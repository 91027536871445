//大数计算库 https://mikemcl.github.io/bignumber.js/
import bn from "bignumber.js";
bn.config({ EXPONENTIAL_AT: 999999, DECIMAL_PLACES: 40 })

export default {
    //Q64.96转小数  单价/BNB
    X96ToFloat(unm) {
        let s = (unm / (2 ** 96)) ** 2
        return s
    },
    //Q64.96转小数 反转 单价/USDT
    X96ToFloat2(unm, f) {
        let s = (unm / (2 ** 96)) ** 2
        return (1 / s).toFixed(f)
    },
    //数额,精度,保留小数点位数
    weiToEth: function (wei, decimals, f) {
        return (wei / Math.pow(10, decimals)).toFixed(f);
    },
    weiToEth2: function (wei, decimals, f) {
        return new bn(wei).dividedBy(Math.pow(10, decimals)).decimalPlaces(f,1).toString()

    },
    //延时程序
    wait(ms) {
        return new Promise((resolve,) => {
            setTimeout(() => {
                //console.log("Done waiting");
                resolve(ms)
            }, ms)
        })
    },
    //打印
    jsonRPC_Error(errorObj) {
        let errorStr = errorObj.toString()
        errorStr = errorStr.replace(/\s+/g, '');
        if (errorStr.includes("Error:InternalJSON-RPCerror.")) {
            errorStr = errorStr.replace("Error:InternalJSON-RPCerror.", "");
            return JSON.parse(errorStr);
        }
        
        console.log(errorObj)
        return {message:""}
    },
    
}