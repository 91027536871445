<template>
   <div>

       <van-overlay class="wrapper" :show="app_zt == false" z-index="999">
         <van-loading type="spinner" color="#1989fa" />
      </van-overlay> 
      <section class="crypto-world-area">
         <div class="container">
            <div class="row center-box">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="earn-box">
                     <div class="page-title">
                        <div class="title-ico mb-3">
                           <img src="@/assets/images/earn-ico.png" alt="">
                        </div>

                        <h2>{{ $t('社区') }}</h2>
                        <p>{{ $t('c1') }}</p>
                     </div>

                     <div class="crypto-world-main"
                        v-if="SwapPeriphery.user_info.tjr_address != '0x0000000000000000000000000000000000000000' && SwapPeriphery.user_info.tjr_address != ''">
                        <div class="row">
                           <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                              v-if="SwapPeriphery.jd_shouyi[0] > 0">
                              <div class="crypto-world-box">
                                 <h3>{{ $t('普通节点') }}</h3>
                                 <h2>{{ $util.weiToEth(SwapPeriphery.jd_shouyi[0], 18, 3) }}</h2>
                                 <div class="crypto-ico">
                                    <i class="fas fa-coins"></i>
                                 </div>
                              </div>
                           </div>


                           <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                              v-if="SwapPeriphery.jd_shouyi[1] > 0">
                              <div class="crypto-world-box">
                                 <h3>{{ $t('超级节点') }}</h3>
                                 <h2>{{ $util.weiToEth(SwapPeriphery.jd_shouyi[1], 18, 3) }}</h2>
                                 <div class="crypto-ico">
                                    <i class="fas fa-cube"></i>
                                 </div>
                              </div>
                           </div>
                           <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div class="crypto-world-box">
                                 <h3>{{ $t('社区总算力') }}</h3>
                                 <h2>{{ $util.weiToEth(system_toji.sl_z_T, 3, 3) }}</h2>
                                 <div class="crypto-ico">
                                    <i class="fas fa-calendar-alt"></i>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="earn-btn" style="margin-top: 15px;">

                        <van-button plain type="primary" color="#FFFFFF"
                           v-if="(SwapPeriphery.jd_shouyi[0] * 1 + SwapPeriphery.jd_shouyi[1] * 1) > 0"
                           :loading="from_receive_jd.loading"
                           style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;"
                           @click="Receive_jd()">{{ $t('节点领取') }}</van-button>
                     </div>
                  </div>
               </div>
               <div role="tabpanel" class="tab-pane"
                  v-if="SwapPeriphery.user_info.tjr_address == '0x0000000000000000000000000000000000000000'"
                  style="padding-left: 15px; padding-right: 15px;">
                  <div class="swap-area">
                     <div class="row">

                        <div class="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12">
                           <div class="swap-tool">
                              <div class="swap-header">
                                 <h3>{{ $t('绑定邀请人') }}</h3>

                              </div>
                              <div class="swap-body">
                                 <div class="swap-form">


                                    <div class="theme-input-box mt-3">
                                       <label>{{ $t('邀请人地址') }}</label>
                                       <input class="theme-input" style="padding-right: 5px; font-size: 12px;" type="text"
                                          v-model="from_bandin.tjr_address" :placeholder="$t('请填写')">

                                    </div>
                                    <div class="swap-btn text-center mt-4">

                                       <van-button plain type="primary" color="#FFFFFF" :loading="from_bandin.loading"
                                          style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;"
                                          @click="mod_Bandin()">{{ $t('绑定') }}</van-button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="container"
                  v-else-if="SwapPeriphery.user_info.tjr_address != '0x0000000000000000000000000000000000000000' && SwapPeriphery.user_info.tjr_address != ''">
                  <div role="tabpanel" class="tab-pane">
                     <div class="liquidity-box">
                        <div class="liquidity-header">
                           <h3>{{ $t('我的社区') }}</h3>
                        </div>
                        <div class="price-pool mt-3" style="padding-left: 5px; padding-right: 5px;">

                           <ul>
                              <li>{{ $t('我的社区算力') }}<span>{{ $util.weiToEth(SwapPeriphery.dt_shouyi[1], 3, 3) }}</span>
                              </li>
                              <li>{{ $t('已领取') }}<span>{{ $util.weiToEth(SwapPeriphery.user_info.dt_shouyi, 18, 2)
                              }}</span></li>
                              <li>{{ $t('待领取') }}<span>{{ $util.weiToEth(SwapPeriphery.dt_shouyi[0], 18, 3) }}</span></li>


                           </ul>

                        </div>


                        <div class="liquidity-body text-center">

                           <div class="addl-container">

                              <div class="liquidity-btn left">
                                 <van-button plain type="primary" color="#FFFFFF" v-if="SwapPeriphery.dt_shouyi[0] > 0"
                                    :loading="from_receive_dt.loading"
                                    style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;"
                                    @click="Receive_dt()">{{ $t('领取收益') }}</van-button>

                              </div>

                           </div>

                        </div>
                     </div>

                  </div>
                  <div class="row" style="margin-top: 15px;">

                     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="footer-link clearfix">
                           <h3>{{ $t('我的邀请链接') }}:</h3>
                           <div class="subscribe-box">

                              <div class="theme-input-box">
                                 <input class="theme-input" type="text" name="subscribe" v-model="currentUrl">
                                 <button class="subscribe-btn" style="color: black;" @click="copyData(currentUrl)"><i
                                       class="far fa-paper-plane"></i></button>
                              </div>

                           </div>

                        </div>

                        <div class="tab-content">

                           <div role="tabpanel" class="tab-pane fade in active" id="live">

                              <div class="farms-main">
                                 <div class="table-responsive">
                                    <van-button plain type="primary" color="#FFFFFF" v-if="ztjr_list_page.index > -1"
                                       :loading="btn.get_zt_List"
                                       style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;float: right;margin-bottom: 10px;"
                                       @click="get_zt_List()">{{ $t('查询') }}({{ ztjr_list_page.index + 1 }})</van-button>
                                    <table class="table" style="width: 100%;">
                                       <thead>
                                          <tr>
                                             <th style="width: 45%;">{{ $t('地址') }}</th>
                                             <th style="width: 25%;">{{ $t('糖浆池') }}</th>
                                             <th style="width: 30%;">{{ $t('上期收益') }}</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr v-for="(item, index) in ztjr_list" :key="'c' + index">
                                             <td>
                                                ({{ item.idx }}){{ maskStringWithStars(item.address) }}
                                             </td>
                                             <td>
                                                {{ $util.weiToEth(item.sc_tzjb, 18, 2) }}
                                             </td>
                                             <td>
                                                {{ $util.weiToEth(item.shouyi, 18, 2) }}
                                             </td>

                                          </tr>


                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
                  <div class="row" style="margin-top: 15px;" v-if="dt_list.length > 0">

                     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="footer-link clearfix">
                           <h3>{{ $t('算力信息') }}:</h3>
                        </div>

                        <div class="tab-content">

                           <div role="tabpanel" class="tab-pane fade in active" id="live">

                              <div class="farms-main">
                                 <div class="table-responsive">
                                    <table class="table" style="width: 100%;">
                                       <thead>
                                          <tr>
                                             <th>{{ $t('天数') }}</th>
                                             <th>{{ $t('动态算力') }}</th>
                                             <th>{{ $t('加单算力') }}</th>
                                             <th>{{ $t('节点算力') }}</th>

                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr v-for="(item, index) in dt_list" :key="'b' + index">
                                             <td>{{ item.ts }}</td>
                                             <td>{{ $util.weiToEth(item.user_dt_list.sl_T, 3, 3) }}</td>
                                             <td>{{ $util.weiToEth(item.user_jia_list.jia_sl, 3, 3) }}</td>
                                             <td>{{ $util.weiToEth(item.user_jd_list.sl_N, 3, 3) }}</td>


                                          </tr>


                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </section>



   </div>
</template>

<script>
import util from '@/common/util.js'

import SwapUtil from '@/common/SwapUtil.js'
//import { times } from 'uview-ui/libs/function/digit';

export default {
   name: 'IndexPage',
   props: {
      msg: String
   },
   data() {
      return {
         btn: {
            get_zt_List: false
         },
         app_zt: true,
         currentUrl: '',
         from_receive_dt: {
            loading: false
         },
         from_bandin: {
            loading: false,
            tjr_address: ''
         },
         from_receive_jd: {
            loading: false
         },
         SwapPeriphery: {
            user_info:
            {
               tjr_address: '',
               suanli: 0
            },
            dt_shouyi: [0, 0],
            jd_shouyi: [0, 0]
         },
         system_suanli: {},
         system_toji: { sl_z_T: 0 },
         dt_list: [],
         ztjr_list: [],
         ztjr_list_page: {
            index: -1,
            page: 5
         }
      };
   },
   created() {
      this.csh()

   },
   mounted() {
      if (typeof this.$route.query.inviter != 'undefined' && this.$route.query.inviter != '') {
         this.from_bandin.tjr_address = this.$route.query.inviter
      }
   },
   methods: {
      copyData(message) {
         //如果不需要回调：
         this.$copyText(message)
         //如果需要回调：
         this.$copyText(message).then(e => {
            this.$toast({ message: `${this.$t('复制成功')}`, overlay: true });
            console.log(e)
         }, function (e) {
            this.$toast({ message: `${this.$t('复制失败')}`, overlay: true });
            console.log(e)
         })
      },
      async mod_Bandin() {
         if (this.SwapPeriphery.user_info.tjr_address != '0x0000000000000000000000000000000000000000') {
            this.$toast({ message: `${this.$t('您已经绑定过推荐人')}`, overlay: true });
            return
         }
         if (this.from_bandin.tjr_address == "") {
            this.$toast({ message: `${this.$t('请填写推荐人地址')}`, overlay: true });
            return
         }
         this.from_bandin.loading = true
         try {
            if (await SwapUtil.Bandin(this.from_bandin.tjr_address)) {
               await this.get_info()
            }
         } catch (error) {
            this.error_msg(error)
         }
         this.from_bandin.loading = false

      },
      async Receive_jd() {
         this.from_receive_jd.loading = true
         try {
            if (await SwapUtil.Receive_jd()) {
               await this.get_info()
            }
         } catch (error) {
            this.error_msg(error)
         }
         this.from_receive_jd.loading = false
      },
      async Receive_dt() {
         this.from_receive_dt.loading = true
         try {
            if (await SwapUtil.Receive_dt()) {
               await this.get_info()
            }
         } catch (error) {
            this.error_msg(error)
         }
         this.from_receive_dt.loading = false
      },
      //获取直推数据
      async get_zt_List() {
         let State = this.$store.state
         let user_info = State.user_info
         let ethContract = State.ethContract[6]



         this.btn.get_zt_List = true


         for (let i = 0; i < this.ztjr_list_page.page; i++) {
            if (this.ztjr_list_page.index < 0) {
               break
            }
            let ztjr_address = await ethContract.web3Contract.methods.ztjr_list(user_info.address, this.ztjr_list_page.index).call()

            this.ztjr_list.push({ idx: this.ztjr_list_page.index + 1, address: ztjr_address, sc_tzjb: 0.00, loading: false, shouyi: 0.00 })

            this.zt_shouyi(this.ztjr_list.length - 1)
            this.ztjr_list_page.index--
         }



         this.btn.get_zt_List = false
         //console.log(this.ztjr_list)
         return true
      },
      //获取直推收益
      async zt_shouyi(index) {

         let State = this.$store.state
         let ethContract = State.ethContract[6]
         let ethContract10 = State.ethContract[10]
         console.log(this.system_suanli.yxts - 1)
         let user_sy = await ethContract.web3Contract.methods.user_sy_list(this.ztjr_list[index].address, this.system_suanli.yxts - 1).call()
         console.log(user_sy)
         let user_info_list = await ethContract10.web3Contract.methods.user_info_list(this.ztjr_list[index].address).call()
         this.ztjr_list[index].shouyi = user_sy.jt_shouyi * 1 + user_sy.dt_shouyi * 1
         this.ztjr_list[index].sc_tzjb = user_info_list.tzjb * 1


      },
      //获取矿池数据
      async get_mining_List() {

         let State = this.$store.state
         let user_info = State.user_info
         let ethContract = State.ethContract[6]

         this.dt_list = []

         let idx = this.system_suanli.yxts
         for (let i = 0; i < 3; i++) {
            if (idx < 0) {
               break
            }
            let user_dt_list = await ethContract.web3Contract.methods.user_dt_list(user_info.address, idx).call()
            let user_jia_list = await ethContract.web3Contract.methods.user_jia_list(user_info.address, idx).call()
            let user_jd_list = await ethContract.web3Contract.methods.user_jd_list(user_info.address, idx).call()
            this.dt_list.push({ ts: idx, user_dt_list: user_dt_list, user_jia_list: user_jia_list, user_jd_list: user_jd_list })

            idx--
         }


         //console.log(dt_list)
         return true
      },

      async get_info() {
         let State = this.$store.state
         //查询我的矿池信息
         let kc_ethContract = State.ethContract[6]
         let user_info = await kc_ethContract.web3Contract.methods.user_list(State.user_info.address).call()
         this.SwapPeriphery.user_info = user_info
         if (this.SwapPeriphery.user_info.tjr_address == '0x0000000000000000000000000000000000000000') {
            return
         }
         let dt_shouyi = await kc_ethContract.web3Contract.methods.Dt_shouyi(State.user_info.address).call()
         let jd_shouyi = await kc_ethContract.web3Contract.methods.Jd_shouyi(State.user_info.address).call()
         this.system_suanli = await kc_ethContract.web3Contract.methods.system_suanli().call()


         this.SwapPeriphery.dt_shouyi = dt_shouyi
         this.SwapPeriphery.jd_shouyi = jd_shouyi

         this.system_toji = await kc_ethContract.web3Contract.methods.system_toji().call()
         await this.get_mining_List()
         this.ztjr_list_page.index = this.SwapPeriphery.user_info.ztjr_total - 1
      },
      async csh() {
         for (let i = 0; i < 100; i++) {
            //延时0.5秒
            await util.wait(500)
            //app已经准备好了
            if (this.$store.state.app_zt) {
               this.app_zt = false
               await this.get_info()
               this.app_zt = true
               if (this.$store.state.user_info.address != '') {

                  let url = window.location.protocol + "//" + window.location.host + "" + window.location.pathname;
                  console.log(url)
                  //window.location.href.split('?')[0]
                  this.currentUrl = url + '#/?tab=3&inviter=' + this.$store.state.user_info.address;
               }
               break
            }
         }
      },
      error_msg(error) {
         let errObj = {}
         errObj.message = error.message.replace(/\s+/g, '');
         console.log(errObj.message)
         if (errObj.message.includes("MetaMask Tx Signature: User denied transaction signature.") || errObj.message.includes("cancel")) {
            this.$dialog.alert({ message: `${this.$t('用户拒绝签名')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('Bandin()Error:10000')) {
            this.$dialog.alert({ message: `${this.$t('您已经绑定过推荐人')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('Bandin()Error:10001')) {
            this.$dialog.alert({ message: `${this.$t('推荐人未激活')}`, theme: 'round-button' })
            return
         }
         this.$dialog.alert({ message: error.toString(), theme: 'round-button' })
         return
      },
      maskStringWithStars(inputString) {
         // 确保输入字符串至少有8个字符，否则不进行任何更改
         if (inputString.length < 8) {
            return inputString;
         }

         // 获取前4个字符和后4个字符
         const prefix = inputString.slice(0, 4);
         const suffix = inputString.slice(-4);

         // 创建中间星号字符串
         const stars = '*'.repeat(3);

         // 拼接前4字符、星号和后4字符
         return prefix + stars + suffix;
      },
   }
}
</script>

<style></style>
