import Vue from 'vue'
import Vuex from 'vuex'
//合约ABI
import ERC20 from '@/common/abi/ERC20.json'//ERC20
import WBNB from '@/common/abi/WBNB.json'//WBNB
import PancakeV2Pair from '@/common/abi/PancakeSwap/PancakeV2Pair.json'//PancakeV2Pair
import pancakeV2Router from '@/common/abi/PancakeSwap/pancakeV2Router.json'//pancakeV2Router
import PancakeV2Factory from '@/common/abi/PancakeSwap/PancakeV2Factory.json'//PancakeV2Factory
import SwapV2ERC20 from '@/common/abi/swapv2/SwapV2ERC20.json'//V2Router01
import V2Router01 from '@/common/abi/swapv2/V2Router01.json'//V2Router01
import V2Factory from '@/common/abi/swapv2/V2Factory.json'//V2Factory
import SwapV2Pair from '@/common/abi/swapv2/SwapV2Pair.json'//V2Factory
import SwapPeriphery from '@/common/abi/swapv2/SwapPeriphery.json'//V2Factory

import SwapWalletLP from '@/common/abi/swapv2/SwapWalletLP.json'//SwapWalletLP
import SwapWalletToken from '@/common/abi/swapv2/SwapWalletToken.json'//SwapWalletToken

import SwapIdo from '@/common/abi/swapv2/SwapIdo.json'
import SwapSc from '@/common/abi/swapv2/SwapSc.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    message: '',
    //应用状态
    app_zt: false,
    //默认节点
    web3_id: 0,
    //钱包信息
    user_info: {
      'address': '',
      'zjb': 0,
      'SwapPeriphery':{
        'owner':'0x0000000000000000000000000000000000000000',
        'STAddress':'0x0000000000000000000000000000000000000000'
      },
      'time':'',
    },
    //只初始化一次
    csh_zt: false,
    //MetaMask钱包对象
    ethereum: null,
    //合约信息
    ethContract: [
      //0:PancakeV2Pair USDT/BNB
      {
        idx: 0,
        address: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE', abi: PancakeV2Pair, web3Contract: null, v2Pool: {
          status: true, name: 'USDT/BNB', token0: 2, token1: 1, reserves: [], totalSupply: 0, pool_status: true, router_idx: 3
        }
      },
      //1:V2Router01
      { idx: 1, address: '0x89911ca4dfDF62ac0519B390B0baA876F7746A42', abi: V2Router01, web3Contract: null, v2Pool: { status: false } },
      //2:V2Factory
      { idx: 2, address: '0xF80a05C5768dF72b6a0527B7F40D997F7951Ecc7', abi: V2Factory, web3Contract: null, v2Pool: { status: false } },
      //3:pancakeV2Router
      { idx: 3, address: '0x10ED43C718714eb63d5aA57B78B54704E256024E', abi: pancakeV2Router, web3Contract: null, v2Pool: { status: false } },
      //4:PancakeV2Factory
      { idx: 4, address: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73', abi: PancakeV2Factory, web3Contract: null, v2Pool: { status: false } },
      //5:SwapV2Pair 内池USDT/Token
      {
        idx: 5,
        address: '', abi: SwapV2Pair, web3Contract: null, v2Pool: {
          status: true, name: 'USDT/FTT', token0: 2, token1: 3, reserves: [], totalSupply: 0, pool_status: true, router_idx: 1
        }
      },
      //6:SwapPeriphery
      { idx: 6, address: '0x04A87DB21E8017afcaf6F2A21A5AF62894579D3F', abi: SwapPeriphery, web3Contract: null, v2Pool: { status: false } },
      //7:pancakeV2Pair 外池USDT/Token
      {
        idx: 7,
        address: '', abi: SwapV2Pair, web3Contract: null, v2Pool: {
          status: true, name: 'USDT/FTT', token0: 2, token1: 3, reserves: [], totalSupply: 0, pool_status: true, router_idx: 3
        }
      },
      //8:SwapWalletLP合约
      { idx: 8, address: '0x5BfbD59FFaecdEaC2b6312B3BE21791a6b1fEb76', abi: SwapWalletLP, web3Contract: null, v2Pool: { status: false }},
      //9:IDO合约
      { idx: 9, address: '0x048f3f9371F3636426448dE6F8B0B864A3A4ACcE', abi: SwapIdo, web3Contract: null, v2Pool: { status: false }},
      //10:锁仓合约
      { idx: 10, address: '0x33c349FCB72C391154203712B4d814e97997e369', abi: SwapSc, web3Contract: null, v2Pool: { status: false }},
    ],
    //合约信息
    ERC20Contract: [
      //0:BNB
      { idx: 0, address: '0x0000000000000000000000000000000000000000', abi: null, web3Contract: null, name: 'BNB', decimal: [18, 6, 2], info: { balance: 0, balance_status: true, price: 0, v2Pool: 0 } },
      //1:WBNB
      { idx: 1, address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', abi: WBNB, web3Contract: null, name: 'BNB', decimal: [18, 6, 2], info: { balance: 0, balance_status: false, price: 0, v2Pool: -1 } },
      //2:USDT
      { idx: 2, address: '0x55d398326f99059fF775485246999027B3197955', abi: ERC20, web3Contract: null, name: 'USDT', decimal: [18, 2, 2], info: { balance: 0, balance_status: true, price: 1, v2Pool: -1 } },
      //3:Test Token
      { idx: 3, address: '0xe1b36097b2941C6f8528BeB2176fb2e2F9F0E261', abi: SwapV2ERC20, web3Contract: null, name: 'FTT', decimal: [18, 2, 8], info: { balance: 0, balance_status: true, price: 0, v2Pool: 5 } },
    ],
    //产币分配SwapWalletToken合约信息
    SwapWalletContract: [
      { idx: 0, address: '0x66Aff9349Fc615be9FFFe07c655E633922Ca2Caa', abi: SwapWalletToken, web3Contract: null},
      { idx: 1, address: '0x91a0d5dA9Edf1512ebDdB1D05e028E0f088E38dc', abi: SwapWalletToken, web3Contract: null},
      { idx: 2, address: '0x445782D56AC4B1dBe515f2fC9B56671cA84D7E4d', abi: SwapWalletToken, web3Contract: null},
      { idx: 3, address: '0x7D5560082C2eA15685f262D7d4Db4BE3b873C8Ab', abi: SwapWalletToken, web3Contract: null},
      { idx: 4, address: '0xb0a85903aE03ACB028bf99B464FDd8A0ac3E76f6', abi: SwapWalletToken, web3Contract: null},
      //6和7授权给锁仓合约10
      { idx: 5, address: '0x21EAA5a86d5dCDEd5f9A0642f2973441E877C1Ba', abi: SwapWalletToken, web3Contract: null},
      { idx: 6, address: '0xEEde57555122a9835Cd6dD9D4131346F4ffe59e1', abi: SwapWalletToken, web3Contract: null},
    ],
    //所有节点信息
    blockchain_list: [
      //MetaMask钱包
      {
        name: 'MetaMask',
        url: 'wss://speedy-nodes-nyc.moralis.io/990a19aebafcfb47c7cc98cf/bsc/mainnet/ws',
        type: 'https',
        web3: null
      },
      //主要节点
      // {
      //   name: '公共节点',
      //   url: 'https://bsc-dataseed.binance.org/',
      //   type: 'https',
      //   web3: null
      // },
      //WSS节点-查询速度快
      // {
      //   name: 'moralis.io',
      //   url: 'wss://speedy-nodes-nyc.moralis.io/990a19aebafcfb47c7cc98cf/bsc/mainnet/ws',
      //   type: 'wss',
      //   web3: null,
      //   user_info: {
      //     'address': null,
      //     'balance': 0,
      //   }
      // }
    ],
  },
  getters: {
  },
  mutations: {



  },
  actions: {
  },
  modules: {
  }
})
