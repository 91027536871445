<template>
   <div>

      <van-overlay class="wrapper" :show="app_zt == false" z-index="999">
         <van-loading type="spinner" color="#1989fa" />
      </van-overlay>
      <section class="items-area" style="padding-top: 15px;">
         <div class="container">
            <div class="row">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="nft-item-main">
                     <div class="nft-item-box text-center">

                        <h2>{{ $t('算力矿池') }}</h2>
                        <p class="theme-description mb-4">
                           {{ $t('b1') }}</p>
                        <div class="item-list-box clearfix"
                           v-if="SwapPeriphery.system_ido.ido_status === true && SwapPeriphery.user_info.tjr_address != '0x0000000000000000000000000000000000000000'">
                           <div class="item-list text-center">
                              <p class="theme-description mb-1">{{ $t('全网剩余额度') }}</p>
                              <h3>{{ $util.weiToEth(SwapPeriphery.system_ido.ido_usdt_total -
                                 SwapPeriphery.system_ido.ido_leiji, 18, 0) }}USDT</h3>
                              <p class="theme-description mb-1">{{ $t('最少数量') }}</p>
                              <h3>{{ $util.weiToEth(SwapPeriphery.system_ido.ido_min, 18, 0) }}USDT</h3>
                              <p class="theme-description mb-1">{{ $t('最大数量') }}</p>
                              <h3>{{ $util.weiToEth(SwapPeriphery.system_ido.ido_max, 18, 0) }}USDT</h3>
                              <p class="theme-description mb-1">{{ $t('我的IDO数量') }}</p>
                              <h3>{{ $util.weiToEth(SwapPeriphery.user_info.tzjb, 18, 0) }}USDT</h3>
                              <p class="theme-description mb-1">{{ $t('IDO算力倍率') }}</p>
                              <h3>{{ $util.weiToEth(SwapPeriphery.system_ido.ido_bs, 3, 3) }}{{ $t('倍') }}</h3>
                           </div>
                        </div>
                      
                        <div class="item-list-box clearfix" style="margin-top: 20px;">
                           <div class="item-list text-center">
                              <p class="theme-description mb-1">{{ $t('全网总算力') }}</p>
                              <h3>{{ $util.weiToEth(SwapPeriphery.system_toji.suanli_z, 3, 3) }}</h3>
                           </div>
                           <div class="item-list text-center">
                              <p class="theme-description mb-1">{{ $t('今日总加单算力') }}</p>
                              <h3>{{ $util.weiToEth(SwapPeriphery.system_toji.jia_sl_z, 3, 3) }}</h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div role="tabpanel" class="tab-pane"
               v-if="SwapPeriphery.user_info.tjr_address == '0x0000000000000000000000000000000000000000'">
               <div class="swap-area">
                  <div class="row">

                     <div class="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12">
                        <div class="swap-tool">
                           <div class="swap-header">
                              <h3>{{ $t('绑定邀请人') }}</h3>

                           </div>
                           <div class="swap-body">
                              <div class="swap-form">


                                 <div class="theme-input-box mt-3">
                                    <label>{{ $t('邀请人地址') }}</label>
                                    <input class="theme-input" style="padding-right: 5px;" type="text"
                                       v-model="from_bandin.tjr_address" :placeholder="$t('请填写')">

                                 </div>
                                 <div class="swap-btn text-center mt-4">

                                    <van-button plain type="primary" color="#FFFFFF" :loading="from_bandin.loading"
                                       style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;"
                                       @click="mod_Bandin()">{{ $t('绑定') }}</van-button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div role="tabpanel" class="tab-pane"
               v-else-if="SwapPeriphery.user_info.tjr_address != '0x0000000000000000000000000000000000000000' && SwapPeriphery.user_info.tjr_address != ''">
               <div class="liquidity-box">
                  <div class="liquidity-header">
                     <h3>{{ $t('我的矿池') }}</h3>
                  </div>
                  <div class="price-pool mt-3" style="padding-left: 5px; padding-right: 5px;">

                     <ul>
                        <li>{{ $t('我的算力') }}<span>{{ $util.weiToEth(SwapPeriphery.user_info.suanli, 3, 3) }}</span></li>
                        <li>{{ $t('已领取') }}<span>{{ $util.weiToEth(SwapPeriphery.user_info.jt_shouyi, 18, 3) }}</span></li>
                        <li>{{ $t('待领取') }}<span>{{ $util.weiToEth(SwapPeriphery.d_jt_souyi[0], 18, 3) }}</span></li>
                        <li v-if="SwapPeriphery.d_jt_souyi[1] > 0">{{ $t('加单收益') }}<span>{{ $util.weiToEth(SwapPeriphery.d_jt_souyi[1],
                           18, 3) }}</span></li>

                     </ul>

                  </div>


                  <div class="liquidity-body text-center">

                     <div class="addl-container">
                        <div class="liquidity-btn left">
                           <a href="javascript:void(0);" @click="toggle_invest()" class="theme-btn">{{ $t('增加算力') }}</a>
                        </div>
                        <div class="liquidity-btn left" style="margin-left: 50px;"
                           v-if="(SwapPeriphery.d_jt_souyi[0] + SwapPeriphery.d_jt_souyi[1]) > 0">
                           <van-button plain type="primary" color="#FFFFFF" :loading="from_receive_jt.loading"
                              style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;"
                              @click="Receive_jt()">{{ $t('领取收益') }}</van-button>

                        </div>

                     </div>

                  </div>
               </div>

            </div>
         </div>
      </section>
      <van-overlay class="wrapper" :show="from_invest.show_invest">
         <div class="modal farm-popup" style="display: block;" aria-hidden="true"
            v-if="SwapPeriphery.user_info.tjr_address != '0x0000000000000000000000000000000000000000' && SwapPeriphery.user_info.tjr_address != ''">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" style="font-size: 18px;">{{ $t('增加算力') }}
                     </h5>
                     <div class="popup-btn">
                        <!-- <i class="fas fa-cog"></i> -->
                        <i class="far fa-times-circle close" @click="toggle_invest()" data-dismiss="modal"></i>
                     </div>
                  </div>
                  <div class="modal-body">

                     <!-- Tab panes -->
                     <div class="tab-content">
                        <div role="tabpanel" class="tab-pane fade show" style="display: block;" id="staking">
                           <div class="swap-tool">

                              <div class="swap-body" style="margin-top: 10px;">
                                 <div class="swap-form">
                                    <div class="farm-percent clearfix">

                                       <a href="javascript:void(0);" @click="from_invest.usdt_amount = $util.weiToEth2($store.state.ERC20Contract[2].info.balance,
                                          $store.state.ERC20Contract[2].decimal[0],
                                          $store.state.ERC20Contract[2].decimal[1])">max</a>
                                    </div>
                                    <div class="swap-input-box">
                                       <div class="swap-input-main">
                                          <h3>{{$store.state.ERC20Contract[2].name}}</h3>
                                          <input class="swap-input" type="number" placeholder="0" name="balance"
                                             v-model="from_invest.usdt_amount">
                                          <span class="swap-balance">
                                             {{
                                                $util.weiToEth2($store.state.ERC20Contract[2].info.balance,
                                                   $store.state.ERC20Contract[2].decimal[0],
                                                   $store.state.ERC20Contract[2].decimal[1])
                                             }}{{$store.state.ERC20Contract[2].name}}
                                          </span>
                                       </div>
                                    </div>
                                    <div class="swap-btn text-center mt-4">

                                       <van-button plain type="primary" color="#FFFFFF" :loading="from_invest.loading"
                                          style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;"
                                          @click="mod_Invest()">{{ $t('确定') }}</van-button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>



                     </div>
                  </div>
               </div>
            </div>
         </div>

      </van-overlay>


   </div>
</template>

<script>

import util from '@/common/util.js'

import SwapUtil from '@/common/SwapUtil.js'
export default {
   name: 'MyMiningPage',
   props: {
      msg: String
   },
   data() {
      return {
         app_zt: true,
         from_receive_jt: {
            loading: false
         },
         from_bandin: {
            loading: false,
            tjr_address: ''
         },
         from_invest: {
            show_invest: false,
            loading: false,
            usdt_amount: ''
         },
         SwapPeriphery: {
            system_toji: { suanli_z: 0,jia_sl_z:0 },
            system_ido: {
               ido_status: false
            },
            user_info: {
               tjr_address: '',
               suanli: 0,
               jt_shouyi: [0, 0],
            },
            d_jt_souyi: 0
         }
      };
   },
   created() {
      this.csh()
   },
   mounted() {
      if (typeof this.$route.query.inviter != 'undefined' && this.$route.query.inviter != '') {
         this.from_bandin.tjr_address = this.$route.query.inviter
      }
   },
   methods: {
      toggle_invest() {
         this.from_invest.show_invest = !this.from_invest.show_invest
      },
      async mod_Bandin() {
         if (this.SwapPeriphery.user_info.tjr_address != '0x0000000000000000000000000000000000000000') {
            this.$toast({ message: `${this.$t('您已经绑定过推荐人')}`, overlay: true });
            return
         }
         if (this.from_bandin.tjr_address == "") {
            this.$toast({ message: `${this.$t('请填写推荐人地址')}`, overlay: true });
            return
         }
         this.from_bandin.loading = true
         try {
            if (await SwapUtil.Bandin(this.from_bandin.tjr_address)) {
               await this.get_info()
            }
         } catch (error) {
            this.error_msg(error)
         }
         this.from_bandin.loading = false

      },
      async mod_Invest() {
         let State = this.$store.state
         let web3 = State.blockchain_list[State.web3_id].web3

         if (this.SwapPeriphery.user_info.tjr_address == '0x0000000000000000000000000000000000000000') {
            this.$toast({ message: `${this.$t('请先绑定推荐人')}`, overlay: true });
            return
         }

         if ((this.SwapPeriphery.d_jt_souyi[0] * 1 + this.SwapPeriphery.d_jt_souyi[1] * 1) > 0) {
            this.$toast({ message: `${this.$t('请先领取静态收益')}`, overlay: true });
            return
         }
         let kc_ethContract = State.ethContract[6]
         let dt_shouyi = await kc_ethContract.web3Contract.methods.Dt_shouyi(State.user_info.address).call()
         let jd_shouyi = await kc_ethContract.web3Contract.methods.Jd_shouyi(State.user_info.address).call()
         if (dt_shouyi[0] * 1 > 0) {
            this.$toast({ message: `${this.$t('请先领取动态收益')}`, overlay: true });
            return
         }
         if ((jd_shouyi[0] * 1 + jd_shouyi[1] * 1) > 0) {
            this.$toast({ message: `${this.$t('请先领取节点收益')}`, overlay: true });
            return
         }

         if (this.from_invest.usdt_amount <= 0 || this.from_invest.usdt_amount == '') {
            this.$toast({ message: `${this.$t('请填写')} USDT ${this.$t('数量')}`, overlay: true });
            return
         }
         let usdt_amount = web3.utils.toWei(String(this.from_invest.usdt_amount), 'ether')

         if (State.ERC20Contract[2].info.balance * 1 < usdt_amount * 1) {
            this.$toast({ message: `${State.ERC20Contract[2].name} ${this.$t('余额不足')}`, overlay: true });
            return
         }
         this.from_invest.loading = true
         try {

            if (this.SwapPeriphery.system_ido.ido_status === true) {
               if (await SwapUtil.Invest_ido(usdt_amount)) {
                  await this.get_info()
                  this.from_invest.show_invest = false
               }
            } else {
               if (await SwapUtil.Invest(usdt_amount)) {
                  await this.get_info()
                  this.from_invest.show_invest = false
               }
            }

         } catch (error) {
            this.error_msg(error)
         }
         this.from_invest.loading = false

      },
      async get_info() {
         let State = this.$store.state
         //查询我的矿池信息
         let ethContract6 = State.ethContract[6]
         let ethContract9 = State.ethContract[9]
         let user_info = await ethContract6.web3Contract.methods.user_list(State.user_info.address).call()
         let jt_shouyi = await ethContract6.web3Contract.methods.Jt_shouyi(State.user_info.address).call()
         this.SwapPeriphery.system_toji = await ethContract6.web3Contract.methods.system_toji().call()

         this.SwapPeriphery.system_ido = await ethContract9.web3Contract.methods.system_ido().call()

         this.SwapPeriphery.user_info = user_info
         this.SwapPeriphery.d_jt_souyi = jt_shouyi

   
      },
      async Receive_jt() {
         this.from_receive_jt.loading = true
         try {
            if (await SwapUtil.Receive_jt()) {
               await this.get_info()
            }
         } catch (error) {
            this.error_msg(error)
         }
         this.from_receive_jt.loading = false
      },
      async csh() {

         for (let i = 0; i < 100; i++) {
            //延时0.5秒
            await util.wait(500)
            //app已经准备好了
            if (this.$store.state.app_zt) {
               this.app_zt = false
               await this.get_info()
               this.app_zt = true
               break
            }
         }

      },
      error_msg(error) {
         let errObj = {}
         errObj.message = error.message.replace(/\s+/g, '');
         console.log(errObj.message)
         if (errObj.message.includes("MetaMask Tx Signature: User denied transaction signature.") || errObj.message.includes("cancel")) {
            this.$dialog.alert({ message: `${this.$t('用户拒绝签名')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('Invest_ido()Error:10001')) {
            this.$dialog.alert({ message: `${this.$t('请先绑定推荐人')}`, theme: 'round-button' })
            return
         }

         if (errObj.message.includes('Bandin()Error:10000')) {
            this.$dialog.alert({ message: `${this.$t('您已经绑定过推荐人')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('Bandin()Error:10001')) {
            this.$dialog.alert({ message: `${this.$t('推荐人未激活')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('Invest()Error:10001')) {
            this.$dialog.alert({ message: `${this.$t('')}投入USDT数量不正确`, theme: 'round-button' })
            return
         }

         if (errObj.message.includes('Invest_ido()Error:10002')) {
            let val = util.weiToEth(this.SwapPeriphery.system_ido.ido_min, 18, 3)
            this.$dialog.alert({ message: `${this.$t('数量')}${val}${this.$t('起')},${val}${this.$t('的倍数')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('Invest_ido()Error:10003')) {
            this.$dialog.alert({ message: `${this.$t('单个账户最高累计IDO')}:${util.weiToEth(this.SwapPeriphery.system_ido.ido_max, 18, 3)}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('Invest_ido()Error:10004')) {
            this.$dialog.alert({ message: `${this.$t('IDO剩余额度不足')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('system_status()Error:10000')) {
            this.$dialog.alert({ message: `${this.$t('功能暂未开放')}`, theme: 'round-button' })
            return
         }
         this.$dialog.alert({ message: error.toString(), theme: 'round-button' })
         return

      }
   }
}
</script>

<style></style>
