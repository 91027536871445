import util from '@/common/util.js'

import Web3Util from '@/common/Web3Util.js'

import vuex from '@/store/index.js'
import { fromRpcSig } from 'ethereumjs-util'

//大数计算库 https://mikemcl.github.io/bignumber.js/
//初始化 new bn('1.05') 传入字符串  输出x.toString(); x.toFixed(); 
//+:x.plus(0.2) -:minus(0.1) *:multipliedBy(3) /dividedBy(y):
//import bn from "bignumber.js";

//默认web3
//let web3=vuex.state.blockchain_list[vuex.state.web3_id].web3
//合约对像
//let contract = vuex.state.ethContract[6].web3Contract

export default {
    //节点数据更新
    async Set_jd_cs(panarm) {
        console.log('====Set_jd_cs====')
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[6]
        let bnb_amount = '0'
        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.Set_jd_cs(panarm).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
    },

    //IDO投入USDT
    async Invest_ido(usdt_amount) {
        console.log('====Invest_ido====')
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[9]
        let bnb_amount = '0'

        let ercContract = vuex.state.ERC20Contract[2]
        //授权token0给合约
        let res = await this.approve_transfer(ercContract, usdt_amount, ethContract.address, user_info)
        if (res.status !== true) {
            return res
        }
        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.Invest_ido(usdt_amount).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
    },
    //投入USDT
    async Invest(usdt_amount) {
        console.log('====Invest====')
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[6]
        let bnb_amount = '0'

        let ercContract = vuex.state.ERC20Contract[2]
        //授权token0给合约
        let res = await this.approve_transfer(ercContract, usdt_amount, ethContract.address, user_info)
        if (res.status !== true) {
            return res
        }



        //有效期5分钟
        let deadline = String(Math.floor(Date.now() / 1000) + 60 * 5)
        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.Invest(usdt_amount, deadline).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
    },
    //质押
    async Sc_add(amount,sc_type) {
        console.log('====Sc_add====')
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[10]
        let bnb_amount = '0'

        let ercContract = vuex.state.ERC20Contract[3]
        //授权token0给合约
        let res = await this.approve_transfer(ercContract, amount, ethContract.address, user_info)
        if (res.status !== true) {
            return res
        }

        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.Sc_add(amount, sc_type).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
    },
    //绑定推荐人
    async Bandin(tjr_address) {
        console.log('====Bandin====')
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[6]
        let bnb_amount = '0'
        var encodedData = await ethContract.web3Contract.methods.Bandin(tjr_address).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
    },

    async add_pool(item) {
        console.log('====add_pool====')
        let user_info = vuex.state.user_info
        let idx = vuex.state.ethContract[item.idx].v2Pool.router_idx
        let ethContract = vuex.state.ethContract[idx]
        let web3 = vuex.state.blockchain_list[vuex.state.web3_id].web3
        let params = {
            tokenA: item.token0.address,
            tokenB: item.token1.address,
            amountADesired: web3.utils.toWei(String(item.from_add.token0Amount), 'ether'),
            amountBDesired: web3.utils.toWei(String(item.from_add.token1Amount), 'ether'),
            amountAMin: web3.utils.toWei(String(item.from_add.token0Amount * item.from_add.huadian), 'ether'),
            amountBMin: web3.utils.toWei(String(item.from_add.token1Amount * item.from_add.huadian), 'ether'),
            to: user_info.address,
            //有效期5分钟
            deadline: String(Math.floor(Date.now() / 1000) + 60 * 5)
        }

        //授权token0给合约
        let res = await this.approve_transfer(item.token0, params.amountADesired, ethContract.address, user_info)
        if (res.status !== true) {
            return res
        }
        //授权token1给合约
        let res2 = await this.approve_transfer(item.token1, params.amountBDesired, ethContract.address, user_info)
        if (res2.status !== true) {
            return res2
        }
        let bnb_amount = '0'

        console.log(params)

        var encodedData = await ethContract.web3Contract.methods.addLiquidity(
            params.tokenA,
            params.tokenB,
            params.amountADesired,
            params.amountBDesired,
            params.amountAMin,
            params.amountBMin,
            params.to,
            params.deadline,
        ).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
    },
    //添加Token和BNB交易对
    async add_pool_eth(item) {
        console.log('====add_pool_eth====')
        
        let user_info = vuex.state.user_info
        let idx = vuex.state.ethContract[item.idx].v2Pool.router_idx
        let ethContract = vuex.state.ethContract[idx]
        let web3 = vuex.state.blockchain_list[vuex.state.web3_id].web3
        let params = {
            token: item.token0.address,
            amountTokenDesired: web3.utils.toWei(String(item.from_add.token0Amount), 'ether'),
            amountTokenMin: web3.utils.toWei(String(item.from_add.token0Amount * item.from_add.huadian), 'ether'),
            amountETHMin: web3.utils.toWei(String(item.from_add.token1Amount * item.from_add.huadian), 'ether'),
            to: user_info.address,
            //有效期5分钟
            deadline: String(Math.floor(Date.now() / 1000) + 60 * 5)
        }
        console.log(item.token0.address)

        //授权token0给合约
        let res = await this.approve_transfer(item.token0, params.amountTokenDesired, ethContract.address, user_info)
        if (res.status !== true) {
            return res
        }
        
        let bnb_amount = web3.utils.toWei(String(item.from_add.token1Amount), 'ether')

        console.log(params)

        //估算Gas费
        //console.log(ethContract)
        var encodedData = await ethContract.web3Contract.methods.addLiquidityETH(
            params.token,
            params.amountTokenDesired,
            params.amountTokenMin,
            params.amountETHMin,
            params.to,
            params.deadline
        ).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
        
    },
    //移除流动性
    async del_pool(item, del_a, amountAMin, amountBMin) {
        console.log('====del_pool====')
        let user_info = vuex.state.user_info
        let idx = vuex.state.ethContract[item.idx].v2Pool.router_idx
        let ethContract = vuex.state.ethContract[idx]

        let deadline = Math.floor(Date.now() / 1000) + 60 * 5
        let ethContract2 = vuex.state.ethContract[item.idx]
        let vrs = await this.sign_approve(ethContract2, user_info, ethContract.address, del_a, deadline, true)

        let params = {
            tokenA: item.token0.address,
            tokenB: item.token1.address,
            liquidity: String(del_a),
            amountAMin: String(amountAMin),
            amountBMin: String(amountBMin),
            to: user_info.address,
            //有效期5分钟
            deadline: String(deadline),
            approveMax: false,
            v: vrs.v,
            r: vrs.r,
            s: vrs.s
        }
        let bnb_amount = '0'
        console.log(params)

        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.removeLiquidityWithPermit(
            params.tokenA,
            params.tokenB,
            params.liquidity,
            params.amountAMin,
            params.amountBMin,
            params.to,
            params.deadline,
            params.approveMax,
            params.v,
            params.r,
            params.s,
        ).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
    },
    //移除流动性-ETH
    async del_pool_eth(item, del_a, amountAMin, amountBMin) {
        console.log('====del_pool_eth====')
        let user_info = vuex.state.user_info
        let idx = vuex.state.ethContract[item.idx].v2Pool.router_idx
        let ethContract = vuex.state.ethContract[idx]

        let deadline = Math.floor(Date.now() / 1000) + 60 * 5
        let ethContract2 = vuex.state.ethContract[item.idx]
        let vrs = await this.sign_approve(ethContract2, user_info, ethContract.address, del_a, deadline, true)

        let params = {
            token: item.token0.address,
            liquidity: String(del_a),
            amountTokenMin: String(amountAMin),
            amountETHMin: String(amountBMin),
            to: user_info.address,
            //有效期5分钟
            deadline: String(deadline),
            approveMax: false,
            v: vrs.v,
            r: vrs.r,
            s: vrs.s
        }
        let bnb_amount = '0'
        console.log(params)

        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.removeLiquidityETHWithPermit(
            params.token,
            params.liquidity,
            params.amountTokenMin,
            params.amountETHMin,
            params.to,
            params.deadline,
            params.approveMax,
            params.v,
            params.r,
            params.s,
        ).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
    },
    //工厂内池代币兑换 输入数量
    async SwapV2swapExactTokensForTokens(item, amountIn) {
        console.log(item.from_swap.list[0].token.name, '-Swap-', item.from_swap.list[1].token.name)
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[6]
        let params = {
            amountIn: amountIn,
            path: [item.from_swap.list[0].token.address, item.from_swap.list[1].token.address],
            to: user_info.address,
            //有效期5分钟
            deadline: String(Math.floor(Date.now() / 1000) + 60 * 5)
        }

        //授权token给合约
        let res = await this.approve_transfer(item.from_swap.list[0].token, params.amountIn, ethContract.address, user_info)
        if (res.status !== true) {
            return res
        }

        let bnb_amount = '0'

        console.log(params)

        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.SwapV2swapExactTokensForTokens(
            params.amountIn,
            params.path,
            params.to,
            params.deadline,
        ).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
    },
    //代币兑换 输入数量 -代币卖出带手续费
    async swapExactTokensForTokensSupportingFeeOnTransferTokens(item, amountIn, amountOutMin) {
        console.log(item.from_swap.list[0].token.name, '-Swap-', item.from_swap.list[1].token.name)
        let user_info = vuex.state.user_info
        let idx = vuex.state.ethContract[item.idx].v2Pool.router_idx
        let ethContract = vuex.state.ethContract[idx]
        let params = {
            amountIn: amountIn,
            amountOutMin: amountOutMin,
            path: [item.from_swap.list[0].token.address, item.from_swap.list[1].token.address],
            to: user_info.address,
            //有效期5分钟
            deadline: String(Math.floor(Date.now() / 1000) + 60 * 5)
        }

        //授权token给合约
        let res = await this.approve_transfer(item.from_swap.list[0].token, params.amountIn, ethContract.address, user_info)
        if (res.status !== true) {
            return res
        }

        let bnb_amount = '0'

        console.log(params)

        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
            params.amountIn,
            params.amountOutMin,
            params.path,
            params.to,
            params.deadline,
        ).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
    },
    //代币兑换 Token-ETH
    async swapExactTokensForETH(item, amountIn, amountOutMin) {
        console.log(item.from_swap.list[0].token.name, '-Swap-', item.from_swap.list[1].token.name)
        let user_info = vuex.state.user_info
        let idx = vuex.state.ethContract[item.idx].v2Pool.router_idx
        let ethContract = vuex.state.ethContract[idx]
        let params = {
            amountIn: amountIn,
            amountOutMin: amountOutMin,
            path: [item.from_swap.list[0].token.address, item.from_swap.list[1].token.address],
            to: user_info.address,
            //有效期5分钟
            deadline: String(Math.floor(Date.now() / 1000) + 60 * 5)
        }

        //授权token给合约
        let res = await this.approve_transfer(item.from_swap.list[0].token, params.amountIn, ethContract.address, user_info)
        if (res.status !== true) {
            return res
        }

        let bnb_amount = '0'

        console.log(params)

        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.swapExactTokensForETH(
            params.amountIn,
            params.amountOutMin,
            params.path,
            params.to,
            params.deadline,
        ).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
        
    },
    //代币兑换 ETH-Token
    async swapExactETHForTokens(item, amountIn, amountOutMin) {
        console.log(item.from_swap.list[0].token.name, '-Swap-', item.from_swap.list[1].token.name)
        let user_info = vuex.state.user_info
        let idx = vuex.state.ethContract[item.idx].v2Pool.router_idx
        let ethContract = vuex.state.ethContract[idx]
        let params = {
            amountOutMin: amountOutMin,
            path: [item.from_swap.list[0].token.address, item.from_swap.list[1].token.address],
            to: user_info.address,
            //有效期5分钟
            deadline: String(Math.floor(Date.now() / 1000) + 60 * 5)
        }

        

        let bnb_amount = amountIn

        console.log(params)

        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.swapExactETHForTokens(
            params.amountOutMin,
            params.path,
            params.to,
            params.deadline,
        ).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
        
    },
    //代币兑换 输入数量
    async swapExactTokensForTokens(item, amountIn, amountOutMin) {
        console.log(item.from_swap.list[0].token.name, '-Swap-', item.from_swap.list[1].token.name)
        let user_info = vuex.state.user_info
        let idx = vuex.state.ethContract[item.idx].v2Pool.router_idx
        let ethContract = vuex.state.ethContract[idx]
        let params = {
            amountIn: amountIn,
            amountOutMin: amountOutMin,
            path: [item.from_swap.list[0].token.address, item.from_swap.list[1].token.address],
            to: user_info.address,
            //有效期5分钟
            deadline: String(Math.floor(Date.now() / 1000) + 60 * 5)
        }

        //授权token给合约
        let res = await this.approve_transfer(item.from_swap.list[0].token, params.amountIn, ethContract.address, user_info)
        if (res.status !== true) {
            return res
        }

        let bnb_amount = '0'

        console.log(params)

        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.swapExactTokensForTokens(
            params.amountIn,
            params.amountOutMin,
            params.path,
            params.to,
            params.deadline,
        ).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
        
    },
    //代币兑换 输出数量
    async swapTokensForExactTokens(item, amountOut, amountInMax) {
        console.log(item.from_swap.list[0].token.name, '-Swap-', item.from_swap.list[1].token.name)
        let user_info = vuex.state.user_info
        let idx = vuex.state.ethContract[item.idx].v2Pool.router_idx
        let ethContract = vuex.state.ethContract[idx]
        let params = {
            amountOut: amountOut,
            amountInMax: amountInMax,
            path: [item.from_swap.list[0].token.address, item.from_swap.list[1].token.address],
            to: user_info.address,
            //有效期5分钟
            deadline: String(Math.floor(Date.now() / 1000) + 60 * 5)
        }

        //授权token给合约
        let res = await this.approve_transfer(item.from_swap.list[0].token, params.amountInMax, ethContract.address, user_info)
        if (res.status !== true) {
            return res
        }

        let bnb_amount = '0'

        console.log(params)

        //估算Gas费
        var encodedData = await ethContract.web3Contract.methods.swapTokensForExactTokens(
            params.amountOut,
            params.amountInMax,
            params.path,
            params.to,
            params.deadline,
        ).encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
        
    },
    //授权USDT检查 数量和地址
    async approve_transfer(token, amount, address, user_info,privatekey='') {
        let ethContract = vuex.state.ERC20Contract[token.idx]
        let web3 = vuex.state.blockchain_list[vuex.state.web3_id].web3

        let ed_amount = web3.utils.toWei('1000000', 'ether')
        if (ed_amount * 1 < amount * 1) {
            ed_amount = amount
        }

        let res = await ethContract.web3Contract.methods.allowance(user_info.address, address).call()
        //如转账数量大于授权数量



        if (amount <= 0) {
            amount = 1 * 10 ** 18
        }

        if (parseInt(amount) > parseInt(res)) {
           let bnb_amount = 0
            console.log("Approve:" + token.name, util.weiToEth(ed_amount, 18, 2))
            //估算Gas费 
            var encodedData = await ethContract.web3Contract.methods.approve(address, ed_amount).encodeABI()
            if(await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount,privatekey)){
                return { status: true }
            }
            
        }
        return { status: true }
    },
    //permit签名授权
    async sign_approve(ethContract, user_info, spender, amount, deadline) {
        //文档https://blog.csdn.net/weixin_43840202/article/details/122957126
        let web3 = vuex.state.blockchain_list[vuex.state.web3_id].web3
        let owner = user_info.address
        let nonce = await ethContract.web3Contract.methods.nonces(user_info.address).call() * 1
        //签名方式一
        let PERMIT_TYPEHASH = await ethContract.web3Contract.methods.PERMIT_TYPEHASH().call()
        let DOMAIN_SEPARATOR = await ethContract.web3Contract.methods.DOMAIN_SEPARATOR().call()
        const structHash = web3.utils.keccak256(web3.eth.abi.encodeParameters(['bytes32', 'address', 'address', 'uint256', 'uint256', 'uint256'], [
            PERMIT_TYPEHASH,
            owner,//你的地址
            spender,//授权给目标地址
            amount,//你要授权的数量
            nonce,//ERC20里面的nonce
            deadline//授权到期时间
        ]
        ))
        const digest = web3.utils.soliditySha3(
            {
                type: 'string',
                value: '\x19\x01'
            },
            {
                type: 'bytes32',
                value: DOMAIN_SEPARATOR
            },
            {
                type: 'bytes32',
                value: structHash
            }
        )
        const signatureHash = await web3.eth.sign(digest, user_info.address);
        //解析签名
        let signature = fromRpcSig(signatureHash)
        let vrs = {
            v: signature.v,
            r: '0x' + signature.r.toString('hex'),
            s: '0x' + signature.s.toString('hex'),
        }
        // console.log('v:', vrs.v);
        // console.log('r:', vrs.r);
        // console.log('s:', vrs.s);
        return vrs
    },
    //每日数据统计
    async DailyStatistics() {
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[6]
        let bnb_amount = '0'
        //估算Gas费 
        var encodedData = await ethContract.web3Contract.methods.DailyStatistics().encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
        
    },
    async Receive_jt() {
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[6]
        let bnb_amount = '0'
        //估算Gas费 
        var encodedData = await ethContract.web3Contract.methods.Receive_jt().encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
        
    },
    async Receive_dt() {
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[6]
        let bnb_amount = '0'
        //估算Gas费 
        var encodedData = await ethContract.web3Contract.methods.Receive_dt().encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
        
    },
    async Receive_jd() {
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[6]
        let bnb_amount = '0'
        //估算Gas费 
        var encodedData = await ethContract.web3Contract.methods.Receive_jd().encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
        
    },

    //收集工具池余额
    async Refund() {
        let user_info = vuex.state.user_info
        let ethContract = vuex.state.ethContract[6]
        let bnb_amount = '0'
        //估算Gas费 
        var encodedData = await ethContract.web3Contract.methods.Refund().encodeABI()
        return await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)
        
    }
}