import util from '@/common/util.js'

import vuex from '@/store/index.js'
import bn from "bignumber.js";
import common from '@/common/common.js'
import Vue from 'vue'

//默认web3
//let web3=vuex.state.blockchain_list[vuex.state.web3_id].web3
//合约对像
//let contract = vuex.state.ethContract[6].web3Contract

export default {
    //预估矿工费->发送交易
    async send_gas_transaction(encodedData, from, to, value,privatekey='') {
        let web3 = vuex.state.blockchain_list[vuex.state.web3_id].web3
        
        //估算Gas费
        let staue = await this.getGas(encodedData, from, to, value)
        if (staue.zt == false) {
            return false
        }
        let gasPrice = await web3.eth.getGasPrice();
        if(privatekey===''){
            let ress= await this.sendTransaction(encodedData, from, to, value, staue.gas,gasPrice);
            //执行成功
            return await this.func_res(ress, true)
        }else{
            
            let ress=  await this.sendSignedTransaction(encodedData, from, to, value, staue.gas,gasPrice,privatekey)
            //执行成功
            return await this.func_res(ress, true)
        }
        
    },
    //私钥签名发送交易
    async sendSignedTransaction(encodedData, from, to, value, gas,gasPrice,privatekey) {
        
        let web3 = vuex.state.blockchain_list[vuex.state.web3_id].web3
        //交易签名
        var sign = await web3.eth.accounts.signTransaction({
            from: from,//发送者账户
            to: to,
            data: encodedData,
            value: String(value),
            gas: new bn(gas).multipliedBy(1.1).integerValue().toString(),
            gasPrice: gasPrice
        }, privatekey);
        console.log(Vue.prototype.$t("交易签名完成->开始发送交易"));
        //同步返回信息
        return new Promise((resolve, reject) => {
            //广播交易
            web3.eth.sendSignedTransaction(sign.rawTransaction)
                .on('transactionHash', () => {
                    console.log(Vue.prototype.$t("交易发送成功"))
                }).on('receipt', (receipt) => {
                    //当交易收据可用时触发
                    console.log(receipt);
                    resolve(receipt)
                }).on('error', function (error) {
                    // 如果交易被网络拒绝并带有收据，则第二个参数将是收据。
                    reject(new Error(Vue.prototype.$t("交易失败:") + error.message))
                })
        })
    },
    //发送交易
    async sendTransaction(encodedData, from, to, value, gas,gasPrice) {
        let web3 = vuex.state.blockchain_list[vuex.state.web3_id].web3
        console.log(Vue.prototype.$t("开始发送交易"));
        
        //同步返回信息
        return new Promise((resolve, reject) => {
            //广播交易
            web3.eth.sendTransaction({
                from: from,//发送者账户
                to: to,
                data: encodedData,
                value: String(value),
                gas: new bn(gas).multipliedBy(1.1).integerValue().toString(),
                gasPrice: gasPrice
            }).on('transactionHash', () => {
                console.log(Vue.prototype.$t("交易发送成功"))
            }).on('receipt', (receipt) => {
                //当交易收据可用时触发
                console.log(receipt);
                resolve(receipt)
            }).on('error', function (error) {
                console.log(Vue.prototype.$t("交易失败:"), error)
                reject(error)
            });
        })
    },
    //估算Gas
    async getGas(encodedData, from, to, value) {
        let bnb_price = vuex.state.ERC20Contract[0].info.price
        let web3 = vuex.state.blockchain_list[vuex.state.web3_id].web3
        let gasPrice = await web3.eth.getGasPrice();
        //估算gas消耗
        let estimateGas = await new Promise((resolve, reject) => {
            web3.eth.estimateGas({
                from: from,
                to: to,
                gas: 8000000,
                data: encodedData,
                value: String(value)
            }, (error, result) => {
                if (error) {
                    console.log('from', from)
                    console.log('to', to)
                    console.log('value', value)
                    console.log(Vue.prototype.$t("估算gas失败"), error)
                    reject(error)
                } else {
                    resolve(result);
                }

            });
        })
        

        if (estimateGas == false) {
            return { zt: false }

        }

        let xysxf = gasPrice * estimateGas;//需要手续费数量

        console.log(Vue.prototype.$t("估算手续费BNB:"), util.weiToEth(xysxf, 18, 6), "~$", (util.weiToEth(xysxf, 18, 6) * bnb_price).toFixed(3))
        return { zt: true, eth_sxf: xysxf, gas: estimateGas }
    },
    async func_res(res, get_user_zt = false) {
        if (res.status) {
            this.sxf_tongji(res)
            if (get_user_zt) {
                await util.wait(1000)
                //重新获取余额
                common.get_user_info()
            }
            return true
        } else {
            console.log(res)
            return false
        }
    },
    sxf_tongji(res) {
        let bnb_price = vuex.state.ERC20Contract[0].info.price
        let sxf = util.weiToEth(res.gasUsed * res.effectiveGasPrice, 18, 6) * bnb_price
        let note = Vue.prototype.$t("交易完成,消耗矿工费$") + sxf.toFixed(3)
        vuex.state.sxf_leiji += sxf * 1
        vuex.state.message = note
        console.log(note)
    },
}