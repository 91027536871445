import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import util from "./common/util.js";
import VueClipboard from 'vue-clipboard2'
//https://youzan.github.io/vant/v2/#/zh-CN/
import 'vant/lib/index.css';
import { Overlay ,Icon,Loading,Button,Col,Row,Toast  } from 'vant';
import VueI18n from 'vue-i18n';





Vue.use(VueI18n);

const messages = {
  en: require('./lang/en.json'),
  jp: require('./lang/jp.json'),
  kr: require('./lang/kr.json'),
  zh: require('./lang/zh.json')
};
let lang = 'en'
if(window.localStorage.getItem('lang')){
  lang = window.localStorage.getItem('lang')
}
const i18n = new VueI18n({
  locale: lang, // 设置默认语言
  messages
});

Vue.prototype._i18n = i18n

Vue.use(Overlay);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Toast);
 
Vue.use(VueClipboard)
Vue.config.productionTip = false

Vue.prototype.$util = util

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
