<template>
   <div>

       <van-overlay class="wrapper" :show="app_zt == false" z-index="999">
         <van-loading type="spinner" color="#1989fa" />
      </van-overlay> 
      <section class="items-area" style="padding-top: 15px;">
         <div class="container">
            <div class="row">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="nft-item-main">
                     <div class="nft-item-box text-center">

                        <h2>{{ $t('糖浆池') }}</h2>
                        <p class="theme-description mb-4">
                           {{ $t('d1') }}</p>


                        <div class="item-list-box clearfix" style="margin-top: 20px;"
                           v-if="SwapPeriphery.system_sc.sc_status === true && SwapPeriphery.user_info.tjr_address != '0x0000000000000000000000000000000000000000'">
                           <div class="item-list text-center">
                              <p class="theme-description mb-1">{{ $t('全网质押') }}</p>
                              <h3>{{ $util.weiToEth(SwapPeriphery.system_sc.sc_total, 18, 3) }}</h3>
                           </div>
                           <div class="item-list text-center">
                              <p class="theme-description mb-1">{{ $t('我的质押') }}</p>
                              <h3>{{ $util.weiToEth(user_info.tzjb, 18, 3) }}</h3>
                           </div>
                           <div class="item-list text-center">
                              <p class="theme-description mb-1">{{ $t('质押收益') }}</p>
                              <h3>{{ $util.weiToEth(user_info.jt_shouyi, 18, 3) }}</h3>
                           </div>
                           <div class="item-list text-center">
                              <p class="theme-description mb-1">{{ $t('社区收益') }}</p>
                              <h3>{{ $util.weiToEth(user_info.dt_shouyi, 18, 3) }}</h3>
                           </div>
                           <div class="item-list text-center">
                              <p class="theme-description mb-1">{{ $t('可用额度') }}</p>
                              <h3>{{ $util.weiToEth(user_info.sc_ed, 18, 3) }}</h3>
                           </div>
                           <div class="item-list text-center" v-if="user_info.ido_tzjb > 0">
                              <van-button plain type="primary" color="#FFFFFF" :loading="btn.Ido_get_ed"
                                 style="width: 130px; height: 35px;font-size: 15px;font-weight: 600;background-color: #EF682A;border-radius:10px;border: 1px solid #FFFFFF;"
                                 @click="Ido_get_ed()">{{ $t('更新额度') }}</van-button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row" style="margin-top: 15px;"
               v-if="SwapPeriphery.system_sc.sc_status === true && SwapPeriphery.user_info.tjr_address != '0x0000000000000000000000000000000000000000'">

               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">


                  <div class="tab-content">

                     <div role="tabpanel" class="tab-pane fade in active" id="live">

                        <div class="farms-main">
                           <div class="table-responsive">
                              <table class="table" style="width: 100%;">
                                 <thead>
                                    <tr>
                                       <th>{{ $t('类别') }}</th>

                                       <th>{{ $t('已质押') }}</th>
                                       <th>{{ $t('待领取') }}</th>
                                       <th>{{ $t('操作') }}</th>

                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr v-for="(item, index) in sc_list" :key="'c' + index">
                                       <td>
                                          {{ item.sc_cs.ts }}{{ $t('天') }}<br>{{ $util.weiToEth(item.sc_cs.sy_bl, 2, 2) }}%
                                       </td>

                                       <td>
                                          {{ item.user_sc_info.sc_amount > 0
                                             ? SwapPeriphery.system_suanli.yxts - item.user_sc_info.sc_ts : 0 }}{{ $t('天')
   }}<br>
                                          {{ $util.weiToEth(item.user_sc_info.sc_amount, 18, 2) }}
                                       </td>
                                       <td>
                                          {{ $util.weiToEth(item.Sc_shouyi, 18, 3) }}<br>
                                          <van-button plain type="primary" color="#FFFFFF" :loading="item.Receive_sc"
                                             v-if="item.Sc_shouyi > 0"
                                             style="width: 60px; height: 30px;font-size: 12px;font-weight: 300;background-color: #EF682A;border-radius:10px;border: 1px solid #FFFFFF;"
                                             @click="Receive_sc(index)">{{ $t('领取') }}</van-button>
                                       </td>
                                       <td>
                                          <van-button plain type="primary" color="#FFFFFF"
                                             style="width: 60px; height: 30px;font-size: 12px;font-weight: 300;background-color: #EF682A;border-radius:10px;border: 1px solid #FFFFFF;"
                                             @click="toggle_invest(index)">{{ $t('添加') }}</van-button><br>
                                          <van-button plain type="primary" color="#FFFFFF" :loading="item.Sc_del"
                                             v-if="SwapPeriphery.system_suanli.yxts - item.user_sc_info.sc_ts >= item.sc_cs.ts && item.user_sc_info.sc_amount > 0"
                                             style="width: 60px; height: 30px;font-size: 12px;font-weight: 300;background-color: #EF682A;border-radius:10px;border: 1px solid #FFFFFF;"
                                             @click="Sc_del(index)">{{ $t('移除') }}</van-button>



                                       </td>

                                    </tr>


                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>

         </div>

      </section>
      <van-overlay class="wrapper" :show="mod_sc.show_invest">
         <div class="modal farm-popup" style="display: block;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" style="font-size: 18px;" v-if="sc_list.length > 0">{{ $t('增加质押') }}({{
                        sc_list[mod_sc.sc_type].sc_cs.ts }}{{ $t('天') }}/{{
      $util.weiToEth(sc_list[mod_sc.sc_type].sc_cs.sy_bl, 2, 2) }}%
                        )</h5>
                     <div class="popup-btn">

                        <i class="far fa-times-circle close" @click="mod_sc.show_invest = false" data-dismiss="modal"></i>
                     </div>
                  </div>
                  <div class="modal-body">


                     <div class="tab-content">
                        <div role="tabpanel" class="tab-pane fade show" style="display: block;" id="staking">
                           <div class="swap-tool">

                              <div class="swap-body" style="margin-top: 10px;">
                                 <div class="swap-form">
                                    <div class="farm-percent clearfix">

                                       <a href="javascript:void(0);" @click="mod_sc.amount = $util.weiToEth2($store.state.ERC20Contract[3].info.balance,
                                          $store.state.ERC20Contract[3].decimal[0],
                                          $store.state.ERC20Contract[3].decimal[1])">max</a>
                                    </div>
                                    <div class="swap-input-box">
                                       <div class="swap-input-main">
                                          <h3>{{ $store.state.ERC20Contract[3].name }}</h3>
                                          <input class="swap-input" type="number" placeholder="0" name="balance"
                                             v-model="mod_sc.amount">
                                          <span class="swap-balance">
                                             {{
                                                $util.weiToEth2($store.state.ERC20Contract[3].info.balance,
                                                   $store.state.ERC20Contract[3].decimal[0],
                                                   $store.state.ERC20Contract[3].decimal[1])
                                             }}{{ $store.state.ERC20Contract[3].name }}
                                          </span>
                                       </div>
                                    </div>
                                    <div class="swap-btn text-center mt-4">
                                       <van-button plain type="primary" color="#FFFFFF" :loading="mod_sc.loading"
                                          style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;"
                                          @click="Sc_add()">{{ $t('确定') }}</van-button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>



                     </div>
                  </div>
               </div>
            </div>
         </div>

      </van-overlay>


   </div>
</template>

<script>

import util from '@/common/util.js'
import Web3Util from '@/common/Web3Util.js'
import SwapUtil from '@/common/SwapUtil.js'
export default {
   name: 'MyMiningPage',
   props: {
      msg: String
   },
   data() {
      return {
         btn: {
            Ido_get_ed: false
         },
         app_zt: true,
         mod_sc: {
            sc_type: 0,
            show_invest: false,
            loading: false,
            amount: ''
         },
         SwapPeriphery: {
            system_suanli: {},
            system_sc: {},
            user_info: {
               tjr_address: ''
            }
         },
         user_info: {},
         sc_list: []


      };
   },
   created() {
      this.csh()
   },
   mounted() {

   },
   methods: {
      toggle_invest(idx) {
         this.mod_sc.sc_type = idx
         this.mod_sc.show_invest = true
      },

      async Sc_add() {
         let State = this.$store.state
         let web3 = State.blockchain_list[State.web3_id].web3

         if (this.SwapPeriphery.user_info.tjr_address == '0x0000000000000000000000000000000000000000') {
            this.$toast({ message: `${this.$t('请先绑定推荐人')}`, overlay: true });
            return
         }

         if ((this.sc_list[this.mod_sc.sc_type].Sc_shouyi) > 0) {
            this.$toast({ message: `${this.$t('请先领取收益')}`, overlay: true });
            return
         }



         if (this.mod_sc.amount <= 0 || this.mod_sc.amount == '') {
            this.$toast({ message: `${this.$t('请填写数量')}`, overlay: true });
            return
         }
         let amount = web3.utils.toWei(String(this.mod_sc.amount), 'ether')

         if (State.ERC20Contract[3].info.balance * 1 < amount * 1) {
            this.$toast({ message: `${State.ERC20Contract[3].name} ${this.$t('余额不足')}`, overlay: true });
            return
         }
         this.mod_sc.loading = true
         try {

            if (await SwapUtil.Sc_add(amount, this.mod_sc.sc_type)) {
               await this.get_info()
               this.mod_sc.show_invest = false
            }

         } catch (error) {
            this.error_msg(error)
         }
         this.mod_sc.loading = false

      },
      async get_info() {
         let State = this.$store.state
         //查询我的矿池信息
         let ethContract6 = State.ethContract[6]
         let ethContract10 = State.ethContract[10]
         let user_info = await ethContract6.web3Contract.methods.user_list(State.user_info.address).call()
         let system_suanli = await ethContract6.web3Contract.methods.system_suanli().call()
         let system_sc = await ethContract10.web3Contract.methods.system_sc().call()
         this.user_info = await ethContract10.web3Contract.methods.user_info_list(State.user_info.address).call()
         this.SwapPeriphery.system_sc = system_sc
         this.SwapPeriphery.user_info = user_info
         this.SwapPeriphery.system_suanli = system_suanli
         this.sc_list = []
         //已质押
         for (let i = 0; i < 4; i++) {
            let sc_cs = await ethContract10.web3Contract.methods.sc_cs_list(i).call()
            let user_sc_info = await ethContract10.web3Contract.methods.user_sc_info_list(State.user_info.address, i).call()
            let Sc_shouyi = await ethContract10.web3Contract.methods.Sc_shouyi(State.user_info.address, i).call()
            //console.log(Sc_shouyi)
            this.sc_list.push({ sc_cs: sc_cs, user_sc_info: user_sc_info, Sc_shouyi: Sc_shouyi * 1, loading: false, Receive_sc: false, Sc_del: false })
         }
         //console.log(sc_list)
      },


      async Ido_get_ed() {
         let State = this.$store.state
         let user_info = State.user_info
         let ethContract = State.ethContract[10]
         this.btn.Ido_get_ed = true
         try {
            let bnb_amount = '0'
            //估算Gas费 
            var encodedData = await ethContract.web3Contract.methods.Ido_get_ed().encodeABI()
            if (await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)) {
               await this.get_info()
            }
         } catch (error) {
            this.error_msg(error)
         }
         this.btn.Ido_get_ed = false
      },
      async Receive_sc(index) {
         let State = this.$store.state
         let user_info = State.user_info
         let ethContract = State.ethContract[10]
         this.sc_list[index].Receive_sc = true
         try {
            let bnb_amount = '0'
            //估算Gas费 
            var encodedData = await ethContract.web3Contract.methods.Receive_sc(index).encodeABI()
            if (await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)) {
               await this.get_info()
            }
         } catch (error) {
            this.error_msg(error)
         }
         this.sc_list[index].Receive_sc = false
      },
      async Sc_del(index) {
         let State = this.$store.state
         let user_info = State.user_info
         let ethContract = State.ethContract[10]
         if ((this.sc_list[index].Sc_shouyi) > 0) {
            this.$toast({ message: `${this.$t('请先领取收益')}`, overlay: true });
            return
         }
         this.sc_list[index].Sc_del = true
         try {
            let bnb_amount = '0'
            //估算Gas费 
            var encodedData = await ethContract.web3Contract.methods.Sc_del(index).encodeABI()
            if (await Web3Util.send_gas_transaction(encodedData, user_info.address, ethContract.address, bnb_amount)) {
               await this.get_info()
            }
         } catch (error) {
            this.error_msg(error)
         }
         this.sc_list[index].Sc_del = false
      },

      async csh() {

         for (let i = 0; i < 100; i++) {
            //延时0.5秒
            await util.wait(500)
            //app已经准备好了
            if (this.$store.state.app_zt) {
               this.app_zt = false
               await this.get_info()
               this.app_zt = true
               break
            }
         }

      },
      error_msg(error) {
         let errObj = {}
         errObj.message = error.message.replace(/\s+/g, '');
         console.log(errObj.message)
         if (errObj.message.includes("MetaMask Tx Signature: User denied transaction signature.") || errObj.message.includes("cancel")) {
            this.$dialog.alert({ message: `${this.$t('用户拒绝签名')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('Sc_add()Error:10001')) {
            this.$dialog.alert({ message: `${this.$t('请先绑定推荐人')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('system_status()Error:10000')) {
            this.$dialog.alert({ message: `${this.$t('未开放')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('Sc_add()Error:10002')) {
            this.$dialog.alert({ message: `${this.$t('请先增加算力')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('Sc_add()Error:10005')) {
            this.$dialog.alert({ message: `${this.$t('没有额度')}`, theme: 'round-button' })
            return
         }


         this.$dialog.alert({ message: error.toString(), theme: 'round-button' })
         return

      }
   }
}
</script>

<style></style>
