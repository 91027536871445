<template>
  <div class="page">

    <header class="header-main">
      <div class="header_1 js-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="mobile-button"><span></span></div>
              <div id="site-header-inner" class="d-flex justify-content-center">
                <div id="site-logo" class="clearfix">
                  <div class="site-logo-inner">
                    <a href="javascript:void(0);" rel="home" class="main-logo">
                      <img id="logo_header" src="@/assets/images/logo.png" alt="nft-gaming">
                    </a>
                  </div>
                </div>

                <div id="site-menu">
                  <div class="nav-responsive" @click="toggleElement">
                    <i class="fas fa-bars"></i>
                  </div>
                  <transition name="fade">
                    <nav id="main-nav" class="main-nav" style="display: block;" v-show="showElement">
                      <ul id="menu-primary-menu" class="menu clearfix">

                        <li class="menu-item " v-for="(item, index) in menu_list" :key="'a' + index">
                          <a href="javascript:void(0);" @click="click_menu(index)">{{ item.name }}</a>
                        </li>
                        <van-row type="flex" justify="space-between" style="margin-top: 5px;padding-bottom: 5px;">
                          <van-button type="primary" icon="./assets/images/lang/en.png" @click="changeLanguage('en')"
                            style="background-color: #3e3e55;border: 0cm;padding: 0 8px; font-size: 12px;">English</van-button>
                          <van-button type="primary" icon="./assets/images/lang/zh.png" @click="changeLanguage('zh')"
                            style="background-color: #3e3e55;border: 0cm;padding: 0 8px; font-size: 12px;">繁體中文</van-button>
                          <van-button type="primary" icon="./assets/images/lang/jp.png" @click="changeLanguage('jp')"
                            style="background-color: #3e3e55;border: 0cm;padding: 0 8px; font-size: 12px;">日本語</van-button>
                          <van-button type="primary" icon="./assets/images/lang/kr.png" @click="changeLanguage('kr')"
                            style="background-color: #3e3e55;border: 0cm;padding: 0 8px; font-size: 12px;">한국인</van-button>
                        </van-row>
                      </ul>
                    </nav>
                  </transition>
                </div>
                <div class="button-connect-wallet">
                  <a href="javascript:void(0);" class="theme-btn" data-toggle="modal" data-target="#connect-wallet"><span
                      v-if="$store.state.user_info.address">{{ maskStringWithStars($store.state.user_info.address)
                      }}</span>

                    <span v-else @click="Connectwallet()">{{ $t('连接钱包') }}</span>

                  </a>
                </div>
                
                <div class="mode_switcher">
                  <h6><span>Dark Mode</span> <strong>Activate</strong></h6>
                  <a href="javascript:void(0);" class="light d-flex align-items-center">
                    <i class="fas fa-sun"></i>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- <IndexPage msg="Dapp" v-if="menu_index == 0" /> -->
    <SwapPage msg="Dapp" v-if="menu_index == 0" />
    <PancakeSwapPage msg="Dapp" v-if="menu_index == 1" />
    <MyMiningPage msg="Dapp" v-if="menu_index == 2" />
    <DtPage msg="Dapp" v-if="menu_index == 3" />
    <ScPage msg="Dapp" v-if="menu_index == 4" />

    <footer class="footer-main" style="padding-top: 30px;">
      <div class="footer-top">
        <div class="container">
          <div class="row">

            <div class="col-xl-3">
              <div class="footer-link clearfix" style="margin-top: 0px;">

                <div class="footer-social">
                  <ul class="clearfix">
                    <li><a href="https://twitter.com/fttswap"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="javascript:void(0);"><i class="fab fa-telegram-plane"></i></a></li>
                    <li><a href="javascript:void(0);"><i class="fab fa-discord"></i></a></li>
                    <li><a href="javascript:void(0);"><i class="fab fa-reddit-alien"></i></a></li>
                    <li><a href="javascript:void(0);"><i class="fab fa-medium-m"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-box">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="copyright-item text-center">
                <p>© Copyright 2023, FTTswap</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
PancakeSwapPage
<script>
//import IndexPage from '@/components/IndexPage.vue'
import SwapPage from '@/components/SwapPage.vue'
import PancakeSwapPage from '@/components/PancakeSwapPage.vue'
import MyMiningPage from '@/components/MyMiningPage.vue'
import DtPage from '@/components/DtPage.vue'
import ScPage from '@/components/ScPage.vue'
import common from '@/common/common.js'

export default {
  components: {
    //IndexPage,
    SwapPage,
    PancakeSwapPage,
    MyMiningPage,
    DtPage,
    ScPage
  },
  data() {
    return {
      menu_index: 0,
      showElement: false,
      queryParams: {}
    };
  },
  computed: {
    menu_list() {
      return [
        //{ id: 0, name: 'Home' },
        { id: 0, name: this.$t('SWAP') },
        { id: 1, name: this.$t('PancakeSWAP') },
        { id: 2, name: this.$t('算力矿池') },
        { id: 3, name: this.$t('社区') },
        { id: 4, name: this.$t('糖浆池') }
        // ,
        // { id: 5, name: this.$t('NEWS') },
        // { id: 6, name: this.$t('审计报告') }

      ];
    }
  },
  created() {

  },
  mounted() {
    document.querySelector('body').setAttribute('style', 'background-color:#10102D;')
    if (this.$route.query.tab == 3) {
      this.menu_index = this.$route.query.tab
    }
  },
  methods: {
    async Connectwallet() {
      common.system_csh()
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale
      this.showElement = false
      window.localStorage.setItem('lang', locale)
    },
    maskStringWithStars(inputString) {
      // 确保输入字符串至少有8个字符，否则不进行任何更改
      if (inputString.length < 8) {
        return inputString;
      }

      // 获取前4个字符和后4个字符
      const prefix = inputString.slice(0, 4);
      const suffix = inputString.slice(-4);

      // 创建中间星号字符串
      const stars = '*'.repeat(3);

      // 拼接前4字符、星号和后4字符
      return prefix + stars + suffix;
    },
    click_menu(index) {
      this.menu_index = index
      this.showElement = false
    },
    toggleElement() {
      this.showElement = !this.showElement;
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '@/assets/css/style.css';
@import '@/assets/css/responsive.css';
@import '@/assets/css/bootstrap.min.css';
@import '@/assets/fontawesome/css/all.min.css';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>
