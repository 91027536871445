<template>
   <div>
      <section style=" height: 50vh;" v-show="app_zt == false">

      </section>
       <van-overlay class="wrapper" :show="app_zt == false" z-index="999">
         <van-loading type="spinner" color="#1989fa" />
      </van-overlay>
      <section class="trade-area page-paddings" style="padding-top: 20px;">
         <div class="container">
            <div class="row">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="trade-main" v-if="Swapv2Pool.length > 0">

                     <div class="swap-area" style="margin-top: 20px;">
                        <div class="row">
                           <div class="col-xl-12">
                              <div class="swap-chart" style="margin-top: 0px;">
                                 <div class="chart-header">
                                    <div class="chart-coin clearfix">
                                       <div class="chart-coin-ico"><img
                                             :src="'./assets/images/coin/' + Swapv2Pool[pool_index].token0.name + '.png'"
                                             alt=""><img
                                             :src="'./assets/images/coin/' + Swapv2Pool[pool_index].token1.name + '.png'"
                                             alt=""></div>
                                       <div class="chart-coin-value"><span>{{ Swapv2Pool[pool_index].token0.name }}/{{
                                          Swapv2Pool[pool_index].token1.name }}</span>
                                       </div>
                                    </div>
                                    <h2>{{ (Swapv2Pool[pool_index].price *
                                       1).toFixed(Swapv2Pool[pool_index].token1.decimal[2])
                                    }}<span>{{ Swapv2Pool[pool_index].token0.name }}</span>
                                    </h2>
                                    <p>{{ $store.state.user_info.time }}</p>

                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="trade-center text-center" style="margin-top: 20px;">
                        <div class="trade-nav">
                           <ul class="nav nav-tabs" role="tablist">
                              <li class="nav-item" v-for="(item, index) in menu_list" :key="'a' + index">
                                 <a class="nav-link" :class="[menu_index == index ? 'active' : '']"
                                    href="javascript:void(0);" role="tab" data-toggle="tab" @click="click_menu(index)">{{
                                       item.name }}</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <!-- Tab panes -->
                     <div class="tab-content">
                        <div role="tabpanel" class="tab-pane fade" :class="[menu_index == 0 ? 'in active' : '']" id="swap">
                           <div class="swap-area">
                              <div class="row">

                                 <div class="col-xl-12">
                                    <div class="swap-tool" style="margin-top: 20px;"
                                       v-if="$store.state.ethContract[Swapv2Pool[pool_index].idx].v2Pool.totalSupply > 0">
                                       <div class="swap-header">
                                          <h3>{{ $t('兑换') }}</h3>
                                          <p>{{ $t('兑换代币,滑点') }}{{ Swapv2Pool[pool_index].from_swap.huadian }}%</p>
                                          <div class="tool-ico">
                                             <a href="javascript:void(0);" data-toggle="modal"
                                                data-target="#recent-transactions"
                                                style="position: relative;margin-right: 10px;">
                                                <van-icon v-show="update == false" @click="update_info()" name="replay"
                                                   size="20px" />
                                                <van-loading v-show="update" size="20px" style="top: -8px;" />
                                             </a>

                                             <a href="javascript:void(0);" data-toggle="modal" @click="toggleSet()"
                                                data-target="#setting-popup"><i class="fas fa-cog"></i></a>
                                          </div>
                                       </div>
                                       <div class="swap-body">
                                          <div class="swap-form">
                                             <div class="swap-input-box">
                                                <label>{{ $t('兑换从') }}</label>
                                                <div class="swap-input-main">
                                                   <div class="swap-box">
                                                      <div class="dropdown">
                                                         <button class="dropdown-select2" type="button" data-toggle="modal"
                                                            data-target="#select-token-popup"><img style="width: 30px;"
                                                               :src="'./assets/images/coin/' + Swapv2Pool[pool_index].from_swap.list[0].token.name + '.png'"
                                                               alt="">
                                                            {{ Swapv2Pool[pool_index].from_swap.list[0].token.name }}
                                                         </button>
                                                      </div>
                                                   </div>
                                                   <input class="swap-input" type="number" placeholder="0.00"
                                                      @input="swap_z(Swapv2Pool[pool_index], 0)" name="balance"
                                                      v-model="Swapv2Pool[pool_index].from_swap.list[0].amount">
                                                   <span class="swap-balance">{{ $t('余额') }} :
                                                      {{
                                                         $util.weiToEth(Swapv2Pool[pool_index].from_swap.list[0].token.info.balance,
                                                            Swapv2Pool[pool_index].from_swap.list[0].token.decimal[0],
                                                            Swapv2Pool[pool_index].from_swap.list[0].token.decimal[1])
                                                      }}</span>
                                                </div>
                                             </div>
                                             <div class="swap-icon"
                                                @click="Swapv2Pool[pool_index].from_swap.list.reverse()"
                                                v-if="system_swap.buy == true">
                                                <img src="@/assets/images/swap-ico.png" alt="">
                                             </div>
                                             <div class="swap-input-box" style="margin-top: 5px;">
                                                <label>{{ $t('兑换到') }}</label>
                                                <div class="swap-input-main">
                                                   <div class="swap-box">
                                                      <div class="dropdown">
                                                         <button class="dropdown-select2" type="button" data-toggle="modal"
                                                            data-target="#select-token-popup"><img style="width: 30px;"
                                                               :src="'./assets/images/coin/' + Swapv2Pool[pool_index].from_swap.list[1].token.name + '.png'"
                                                               alt="">
                                                            {{ Swapv2Pool[pool_index].from_swap.list[1].token.name }}
                                                         </button>
                                                      </div>
                                                   </div>
                                                   <input class="swap-input" type="number" placeholder="0.00"
                                                      @input="swap_z(Swapv2Pool[pool_index], 1)" name="balance"
                                                      v-model="Swapv2Pool[pool_index].from_swap.list[1].amount">
                                                   <span class="swap-balance">{{ $t('余额') }} :
                                                      {{
                                                         $util.weiToEth(Swapv2Pool[pool_index].from_swap.list[1].token.info.balance,
                                                            Swapv2Pool[pool_index].from_swap.list[1].token.decimal[0],
                                                            Swapv2Pool[pool_index].from_swap.list[1].token.decimal[1])
                                                      }}</span>
                                                </div>
                                             </div>
                                             <div class="swap-btn text-center mt-4">
                                                <van-button plain type="primary" color="#FFFFFF"
                                                   :loading="Swapv2Pool[pool_index].from_swap.loading"
                                                   style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;"
                                                   @click="mod_swap_token(Swapv2Pool[pool_index])">{{ $t('兑换')
                                                   }}</van-button>

                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="swap-tool" style="margin-top: 20px;" v-else>
                                       <div class="swap-header">

                                          <p>{{ $t('流动池还未创建') }}</p>

                                       </div>
                                       <div class="swap-body"></div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div role="tabpanel" class="tab-pane fade" :class="[menu_index == 1 ? 'in active' : '']"
                           id="liquidity">
                           <div class="liquidity-box">
                              <div class="liquidity-header">
                                 <h3>{{ $t('您的流动性') }}</h3>

                              </div>
                              <div class="price-pool mt-3" style="padding-left: 5px; padding-right: 5px;"
                                 v-if="$store.state.ethContract[Swapv2Pool[pool_index].idx].v2Pool.totalSupply > 0">
                                 <label>{{ $t('您的流动性') }}LP:{{ $util.weiToEth(Swapv2Pool[pool_index].lp_balance, 18, 2)
                                 }}</label>
                                 <ul>
                                    <li>{{ Swapv2Pool[pool_index].token0.name }}<span>{{
                                       $util.weiToEth(Swapv2Pool[pool_index].token0Amount,
                                          Swapv2Pool[pool_index].token0.decimal[0],
                                          Swapv2Pool[pool_index].token0.decimal[1]) }}</span></li>
                                    <li>{{ Swapv2Pool[pool_index].token1.name }}<span>{{
                                       $util.weiToEth(Swapv2Pool[pool_index].token1Amount,
                                          Swapv2Pool[pool_index].token1.decimal[0],
                                          Swapv2Pool[pool_index].token1.decimal[1]) }}</span></li>
                                    <li>{{ $t('份额') }}<span>{{
                                       $util.weiToEth(Swapv2Pool[pool_index].lp_balance /
                                          $store.state.ethContract[Swapv2Pool[pool_index].idx].v2Pool.totalSupply,
                                          0, 8) }}%</span></li>
                                 </ul>
                                 <label>
                                    {{ Swapv2Pool[pool_index].token0.name }}:
                                    {{
                                       $util.weiToEth($store.state.ethContract[Swapv2Pool[pool_index].idx].v2Pool.reserves[0],
                                          Swapv2Pool[pool_index].token0.decimal[0],
                                          Swapv2Pool[pool_index].token0.decimal[1]) }}
                                    /
                                    {{ Swapv2Pool[pool_index].token1.name }}:
                                    {{
                                       $util.weiToEth($store.state.ethContract[Swapv2Pool[pool_index].idx].v2Pool.reserves[1],
                                          Swapv2Pool[pool_index].token1.decimal[0],
                                          Swapv2Pool[pool_index].token1.decimal[1]) }}
                                 </label>
                              </div>


                              <div class="liquidity-body text-center">
                                 <div class="addl-container">
                                    <div class="liquidity-btn left" v-if="Swapv2Pool[pool_index].lp_balance > 0">
                                       <a href="javascript:void(0);" @click="toggle_pool_del()" class="theme-btn">{{ $t('移除流动性')
                                       }}</a>
                                    </div>
                                    <div class="liquidity-btn addl-right"
                                       v-if="$store.state.ethContract[Swapv2Pool[pool_index].idx].v2Pool.totalSupply > 0">
                                       <a href="javascript:void(0);" @click="toggle_pool_add()" class="theme-btn"
                                          style="margin-left: 20px;">{{ $t('添加流动性') }}</a>
                                    </div>
                                    <div class="liquidity-btn addl-right" v-else>
                                       <a href="javascript:void(0);" @click="toggle_pool_add()" class="theme-btn">{{ $t('创建流动性')
                                       }}</a>
                                    </div>
                                 </div>

                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <van-overlay class="wrapper" :show="show_pool_del">
         <div class="modal farm-popup" style="display: block;" aria-hidden="true" v-if="Swapv2Pool.length > 0">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" style="font-size: 18px;">{{ $t('移除流动性') }}
                        <!--<i class="fas fa-info-circle"></i>-->
                     </h5>
                     <div class="popup-btn">
                        <!-- <i class="fas fa-cog"></i> -->
                        <i class="far fa-times-circle close" @click="toggle_pool_del()" data-dismiss="modal"></i>
                     </div>
                  </div>
                  <div class="modal-body">

                     <!-- Tab panes -->
                     <div class="tab-content">
                        <div role="tabpanel" class="tab-pane fade show" style="display: block;" id="staking">
                           <div class="swap-tool">
                              <div class="swap-header">

                                 <p>{{ $t('a1') }}</p>
                              </div>
                              <div class="swap-body">
                                 <div class="swap-form">
                                    <div class="farm-percent clearfix">
                                       <a href="javascript:void(0);"
                                          @click="Swapv2Pool[pool_index].from_add.del_bl = 25">25%</a>
                                       <a href="javascript:void(0);"
                                          @click="Swapv2Pool[pool_index].from_add.del_bl = 50">50%</a>
                                       <a href="javascript:void(0);"
                                          @click="Swapv2Pool[pool_index].from_add.del_bl = 75">75%</a>
                                       <a href="javascript:void(0);"
                                          @click="Swapv2Pool[pool_index].from_add.del_bl = 100">max</a>
                                    </div>
                                    <div class="swap-input-box">
                                       <div class="swap-input-main">
                                          <h3>%</h3>
                                          <input class="swap-input" type="number" name="balance"
                                             v-model="Swapv2Pool[pool_index].from_add.del_bl">
                                          <span class="swap-balance">{{ $t('比例') }}</span>
                                       </div>
                                    </div>
                                    <div class="swap-btn text-center mt-4">

                                       <van-button plain type="primary" color="#FFFFFF"
                                          :loading="Swapv2Pool[pool_index].from_del.loading"
                                          style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;"
                                          @click="mod_del_pool(Swapv2Pool[pool_index])">{{ $t('移除') }}</van-button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>



                     </div>
                  </div>
               </div>
            </div>
         </div>

      </van-overlay>
      <van-overlay class="wrapper" :show="show_pool_add">
         <div class="modal farm-popup" style="display: block;" aria-hidden="true" v-if="Swapv2Pool.length > 0">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" style="font-size: 18px;">{{ $t('添加流动性') }}
                        <!--<i class="fas fa-info-circle"></i>-->
                     </h5>
                     <div class="popup-btn">

                        <i class="far fa-times-circle close" @click="toggle_pool_add()" data-dismiss="modal"></i>
                     </div>
                  </div>
                  <div class="modal-body">

                     <!-- Tab panes -->
                     <div class="tab-content">
                        <div role="tabpanel" class="tab-pane fade in active" id="liquidity">
                           <div class="swap-tool">
                              <div class="swap-header">

                                 <p style="font-size: 12px;">{{ $t('a2') }}</p>
                              </div>
                              <div class="swap-body">
                                 <div class="swap-form">
                                    <div class="swap-input-box">

                                       <div class="swap-input-main">
                                          <div class="swap-box">
                                             <div class="dropdown">
                                                <button id="dLabel" class="dropdown-select2" type="button"
                                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img  style="width: 30px;"
                                                      :src="'./assets/images/coin/' + Swapv2Pool[pool_index].token0.name + '.png'"
                                                      alt=""> {{ Swapv2Pool[pool_index].token0.name
                                                      }}
                                                   <span class="caret"></span>
                                                </button>

                                             </div>
                                          </div>
                                          <input class="swap-input" type="number" name="balance"
                                             v-model="Swapv2Pool[pool_index].from_add.token0Amount" placeholder="0.00"
                                             @input="onInputChange(Swapv2Pool[pool_index], 0)">
                                          <span class="swap-balance">
                                             {{ $t('余额') }} :
                                             {{
                                                $util.weiToEth(Swapv2Pool[pool_index].token0.info.balance,
                                                   Swapv2Pool[pool_index].token0.decimal[0],
                                                   Swapv2Pool[pool_index].token0.decimal[1])
                                             }}
                                          </span>
                                       </div>
                                    </div>
                                    <div class="swap-icon">
                                       <i class="fas fa-plus"></i>
                                    </div>
                                    <div class="swap-input-box" style="margin-top: 15px;">

                                       <div class="swap-input-main">
                                          <div class="swap-box">
                                             <div class="dropdown">
                                                <button id="dLabel" class="dropdown-select2" type="button"
                                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img  style="width: 30px;"
                                                      :src="'./assets/images/coin/' + Swapv2Pool[pool_index].token1.name + '.png'"
                                                      alt=""> {{ Swapv2Pool[pool_index].token1.name
                                                      }}
                                                   <span class="caret"></span>
                                                </button>

                                             </div>
                                          </div>
                                          <input class="swap-input" type="number" name="balance"
                                             v-model="Swapv2Pool[pool_index].from_add.token1Amount" placeholder="0.00"
                                             @input="onInputChange(Swapv2Pool[pool_index], 1)">
                                          <span class="swap-balance">
                                             {{ $t('余额') }} :
                                             {{
                                                $util.weiToEth(Swapv2Pool[pool_index].token1.info.balance,
                                                   Swapv2Pool[pool_index].token1.decimal[0],
                                                   Swapv2Pool[pool_index].token1.decimal[1])
                                             }}
                                          </span>
                                       </div>
                                    </div>

                                    <div class="swap-btn text-center mt-4">
                                       <van-button plain type="primary" color="#FFFFFF"
                                          :loading="Swapv2Pool[pool_index].from_add.loading"
                                          style="width: 100px;font-size: 16px;font-weight: 600;background-color: #EF682A;border-radius:100px;border: 2px solid #FFFFFF;"
                                          @click="mod_add_pool(Swapv2Pool[pool_index])">{{ $t('添加') }}</van-button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>


                     </div>
                  </div>
               </div>
            </div>
         </div>

      </van-overlay>
      <van-overlay class="wrapper" :show="showSet">
         <div class="modal" id="setting-popup" style="display: block;" v-if="Swapv2Pool.length > 0">
            <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title">{{ $t('设置') }}</h5>
                     <p>{{ $t('事务处理设置') }}</p>
                     <button type="button" class="close" @click="toggleSet()" data-dismiss="modal" aria-label="Close">
                        <i class="far fa-times-circle"></i>
                     </button>
                  </div>
                  <div class="modal-body">
                     <div class="setting-box">
                        <div class="theme-input-box">
                           <label>{{ $t('兑换滑点') }} <i class="fas fa-info-circle"></i></label>
                           <div class="setting-input">
                              <div class="setting-input">
                                 <input class="theme-input" type="number" name="auto"
                                    v-model="Swapv2Pool[pool_index].from_swap.huadian">
                                 <span>%</span>
                              </div>
                              <button class="theme-btn" @click="Swapv2Pool[pool_index].from_swap.huadian = 20">{{ $t('最大')
                              }}</button>
                           </div>
                        </div>
                        <div class="theme-input-box w-60 mt-4">
                           <label>{{ $t('交易截至时间') }} <i class="fas fa-info-circle"></i></label>
                           <div class="setting-input">
                              <div class="setting-input">
                                 <input class="theme-input" type="number" name="minutes"
                                    v-model="Swapv2Pool[pool_index].deadline">
                              </div>
                              <p>{{ $t('分钟') }}</p>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </van-overlay>
   </div>
</template>

<script>
import common from '@/common/common.js'
import util from '@/common/util.js'

import SwapUtil from '@/common/SwapUtil.js'
import bn from "bignumber.js";
export default {
   name: 'PancakeSwapPage',
   props: {
      msg: String
   },
   data() {
      return {
         app_zt: true,
         update: false,
         menu_index: 0,

         pool_index: 0,

         system_swap: {},
         Swapv2Pool: [],
         show_pool_add: false,
         show_pool_del: false,
         showSet: false


      };
   },
   computed: {
      menu_list() {
         return [
            { id: 0, name: this.$t('兑换') },
            { id: 1, name: this.$t('流动性') }
         ]
      }
   },
   created() {
      this.csh()
   },
   mounted() {
   },
   methods: {
      toggleSet() {
         this.showSet = !this.showSet;
      },
      toggle_pool_add() {
         this.show_pool_add = !this.show_pool_add;
      },
      toggle_pool_del() {
         this.show_pool_del = !this.show_pool_del;
      },
      click_menu(index) {
         this.menu_index = index
      },
      click_pool(index) {
         this.pool_index = index
      },
      async swap_z(item, lx) {
         let State = this.$store.state
         let web3 = State.blockchain_list[State.web3_id].web3
         let idx = State.ethContract[item.idx].v2Pool.router_idx
         let ethContract = State.ethContract[idx]
         if (lx == 0) {
            if (item.from_swap.list[0].amount <= 0) {
               return
            }
            //第一个框输入
            let amountIn = web3.utils.toWei(String(item.from_swap.list[0].amount), 'ether')
            let res = await ethContract.web3Contract.methods.getAmountsOut(amountIn, [item.from_swap.list[0].token.address, item.from_swap.list[1].token.address]).call()
            item.from_swap.list[1].amount = util.weiToEth(res[1], item.from_swap.list[1].token.decimal[0], 6)
         }
         if (lx == 1) {
            if (item.from_swap.list[1].amount <= 0) {
               return
            }
            //第二个框输入
            let amountOut = web3.utils.toWei(String(item.from_swap.list[1].amount), 'ether')
            let res = await ethContract.web3Contract.methods.getAmountsIn(amountOut, [item.from_swap.list[0].token.address, item.from_swap.list[1].token.address]).call()
            item.from_swap.list[0].amount = util.weiToEth(res[0], item.from_swap.list[0].token.decimal[0], 6)
         }
      },
      //token兑换
      async mod_swap_token(item) {
         let State = this.$store.state
         let web3 = State.blockchain_list[State.web3_id].web3
         let ethContract = State.ethContract[item.idx]

         let SPAddress = await ethContract.web3Contract.methods.SPAddress().call()
         if (item.from_swap.list[0].amount <= 0) {
            this.$toast({ message: `${this.$t('请填写')} ${item.from_swap.list[0].token.name} ${this.$t('数量')}`, overlay: true });
            return
         }
         if (item.from_swap.list[1].amount <= 0) {
            this.$toast({ message: `${this.$t('请填写')} ${item.from_swap.list[1].token.name} ${this.$t('数量')}`, overlay: true });
            return
         }
         let amountIn = web3.utils.toWei(String(item.from_swap.list[0].amount), 'ether')
         if (item.from_swap.list[0].token.info.balance * 1 < amountIn * 1) {
            this.$toast({ message: `${item.from_swap.list[0].token.name} ${this.$t('余额不足')}`, overlay: true });
            return
         }
         item.from_swap.loading = true
         try {
            //进入工厂交易
            if (SPAddress != '0x0000000000000000000000000000000000000000') {
               //console.log('工厂池交易')
               let kc_ethContract = State.ethContract[6]
               let jt_shouyi = await kc_ethContract.web3Contract.methods.Jt_shouyi(State.user_info.address).call()
               let dt_shouyi = await kc_ethContract.web3Contract.methods.Dt_shouyi(State.user_info.address).call()
               let jd_shouyi = await kc_ethContract.web3Contract.methods.Jd_shouyi(State.user_info.address).call()
               if ((jt_shouyi[0] * 1 + jt_shouyi[1] * 1) > 0) {
                  this.$toast({ message: `${this.$t('请先领取静态收益')}`, overlay: true });
                  item.from_swap.loading = false
                  return
               }
               if (dt_shouyi[0] * 1 > 0) {
                  this.$toast({ message: `${this.$t('请先领取动态收益')}`, overlay: true });
                  item.from_swap.loading = false
                  return
               }
               if ((jd_shouyi[0] * 1 + jd_shouyi[1] * 1) > 0) {
                  this.$toast({ message: `${this.$t('请先领取节点收益')}`, overlay: true });
                  item.from_swap.loading = false
                  return
               }
               if (await SwapUtil.SwapV2swapExactTokensForTokens(item, amountIn)) {
                  await this.update_info()
               }
            } else {
               //console.log('内池交易')
               let amountIn = web3.utils.toWei(String(item.from_swap.list[0].amount), 'ether')
               //最少输出数量 默认滑点0.5%
               let amountOutMin = web3.utils.toWei(String(item.from_swap.list[1].amount * (1 - item.from_swap.huadian / 100)), 'ether')
               if (await SwapUtil.swapExactTokensForTokens(item, amountIn, amountOutMin)) {
                  this.update_info()
               }
            }
         } catch (error) {
            this.error_msg(error)
         }
         item.from_swap.loading = false

      },
      onInputChange(item, lx) {
         if (item.price == 0) {
            return
         }
         if (lx == 0) {
            item.from_add.token1Amount = item.from_add.token0Amount / item.price
         } else {
            item.from_add.token0Amount = item.from_add.token1Amount * item.price
         }
      },
      //添加流动性
      async mod_add_pool(item) {
         if (item.from_add.token0Amount <= 0) {
            this.$toast({ message: `${this.$t('请填写')} ${item.token0.name} ${this.$t('数量')}`, overlay: true });
            return
         }
         if (item.from_add.token1Amount <= 0) {
            this.$toast({ message: `${this.$t('请填写')} ${item.token1.name} ${this.$t('数量')}`, overlay: true });
            return
         }
         item.from_add.loading = true;
         try {
            if (await SwapUtil.add_pool(item)) {
               await this.update_info()
               this.show_pool_add = false
            }
         } catch (error) {
            this.error_msg(error)
         }

         item.from_add.loading = false
      },
      //移除流动性
      async mod_del_pool(item) {
         if (item.lp_balance <= 0) {
            this.$toast({ message: `${this.$t('没有可移除的流动池')}`, overlay: true });
            return
         }
         let del_a = new bn(item.lp_balance).multipliedBy((item.from_add.del_bl / 100)).integerValue().toString()
         if (del_a <= 0) {
            this.$toast({ message: `${this.$t('请填写正确的移除比例')}`, overlay: true });
            return
         }
         if (del_a > item.lp_balance) {
            del_a = item.lp_balance
         }

         let amountAMin = new bn(item.token0Amount).multipliedBy((item.from_add.del_bl / 100)).multipliedBy(0.995).integerValue().toString()
         let amountBMin = new bn(item.token1Amount).multipliedBy((item.from_add.del_bl / 100)).multipliedBy(0.995).integerValue().toString()
         item.from_del.loading = true;
         try {
            if (await SwapUtil.del_pool(item, String(del_a), String(amountAMin), String(amountBMin))) {
               await this.update_info()
               this.show_pool_del = false
            }
         } catch (error) {
            this.error_msg(error)
         }
         item.from_del.loading = false
      },
      async update_info() {
         this.update = true
         await common.get_user_info()
         await this.get_info()
         this.update = false
      },
      async get_info() {
         let State = this.$store.state


         let ethContract6 = State.ethContract[6]
         this.system_swap = await ethContract6.web3Contract.methods.system_swap().call()
         //获取流动池子信息
         let user_v2Pool = []
         for (let i = 0; i < State.ethContract.length; i++) {
            let ethContract = State.ethContract[i]
            if (ethContract.v2Pool.router_idx == 1 && ethContract.v2Pool.pool_status == true) {
               //console.log(ethContract.v2Pool.name)
               if (ethContract.address != '') {


                  ethContract.v2Pool.reserves = await ethContract.web3Contract.methods.getReserves().call()
                  ethContract.v2Pool.totalSupply = await ethContract.web3Contract.methods.totalSupply().call()


                  //获取我的流动性
                  let lp_balance = await ethContract.web3Contract.methods.balanceOf(State.user_info.address).call()
                  let token0Amount = new bn(lp_balance).multipliedBy(ethContract.v2Pool.reserves[0]).dividedBy(ethContract.v2Pool.totalSupply).integerValue().toString()
                  let token1Amount = new bn(lp_balance).multipliedBy(ethContract.v2Pool.reserves[1]).dividedBy(ethContract.v2Pool.totalSupply).integerValue().toString()
                  let price = new bn(ethContract.v2Pool.reserves[0]).dividedBy(ethContract.v2Pool.reserves[1]).toString()
                  user_v2Pool.push({
                     idx: ethContract.idx,
                     lp_balance: lp_balance,
                     token0: State.ERC20Contract[ethContract.v2Pool.token0],
                     token1: State.ERC20Contract[ethContract.v2Pool.token1],
                     token0Amount: token0Amount,
                     token1Amount: token1Amount,
                     //添加池子信息 滑点0.5%
                     from_add: { loading: false, token0Amount: '', token1Amount: '', del_bl: 0, huadian: 0.995 },
                     //代币兑换 滑点1%
                     from_swap: {
                        loading: false,
                        list: [
                           { token: State.ERC20Contract[ethContract.v2Pool.token1], amount: '' },
                           { token: State.ERC20Contract[ethContract.v2Pool.token0], amount: '' },
                        ],
                        huadian: 0.5
                     },
                     from_del: { loading: false },
                     price: price,
                     deadline: 5
                  })


               } else {
                  //创建流动池
                  ethContract.v2Pool.reserves = [0, 0, 0]
                  ethContract.v2Pool.totalSupply = 0
                  if (ethContract.v2Pool.pool_status) {
                     user_v2Pool.push({
                        idx: ethContract.idx,
                        lp_balance: 0,
                        token0: State.ERC20Contract[ethContract.v2Pool.token0],
                        token1: State.ERC20Contract[ethContract.v2Pool.token1],
                        token0Amount: 0,
                        token1Amount: 0,
                        //添加池子信息 滑点0.5%
                        from_add: { loading: false, token0Amount: '', token1Amount: '', del_bl: 0, huadian: 1 },
                        from_swap: {
                           loading: false,
                           list: [
                              { token: State.ERC20Contract[ethContract.v2Pool.token0], amount: '' },
                              { token: State.ERC20Contract[ethContract.v2Pool.token1], amount: '' },
                           ],
                           //代币兑换 滑点1%
                           huadian: 1
                        },
                        from_del: { loading: false },
                        price: 0,
                        deadline: 5
                     })
                  }
               }
            }
         }
         this.Swapv2Pool = user_v2Pool
      },
      async csh() {
         for (let i = 0; i < 100; i++) {
            //延时0.5秒
            await util.wait(500)
            //app已经准备好了
            if (this.$store.state.app_zt) {
               this.app_zt = false
               await this.get_info()
               this.app_zt = true
               break
            }
         }

      },
      error_msg(error) {
         let errObj = {}
         errObj.message = error.message.replace(/\s+/g, '');
         console.log(errObj.message)
         if (errObj.message.includes("MetaMask Tx Signature: User denied transaction signature.") || errObj.message.includes("cancel")) {
            this.$dialog.alert({ message: `${this.$t('用户拒绝签名')}`, theme: 'round-button' })
            return
         }

         if (errObj.message.includes('INSUFFICIENT_OUTPUT_AMOUNT')) {
            this.$dialog.alert({ message: `${this.$t('交易滑点过大,请提高交易滑点')}`, theme: 'round-button' })
            return
         }
         if (errObj.message.includes('P_swapExactTokensForTokens()Error:10002')) {
            this.$dialog.alert({ message: `${this.$t('内池只能卖出不能买入')}`, theme: 'round-button' })
            return
         }
         this.$dialog.alert({ message: error.toString(), theme: 'round-button' })

         return



      }
   }
}
</script>

<style>
.wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
}


.addl-container {
   display: flex;
   justify-content: center;
   /* 水平居中 */
   align-items: center;
   /* 垂直居中 */
}</style>
