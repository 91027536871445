import Web3 from 'web3'
import vuex from '@/store/index.js'
import bn from "bignumber.js";
import { Dialog } from 'vant';
import Vue from 'vue'

export default {
    //初始化完成标记
    csh_cg() {
        vuex.state.app_zt = true
        console.log(Vue.prototype.$t("Dapp初始化完成"))
        return true
    },
    web3Contract_csh() {
        //默认web3对像
        let web3 = vuex.state.blockchain_list[vuex.state.web3_id].web3
        //创建查合约对像
        for (let i = 0; i < vuex.state.ethContract.length; i++) {
            let that = vuex.state.ethContract[i]
            that.web3Contract = new web3.eth.Contract(that.abi, that.address);
        }
        for (let i = 0; i < vuex.state.ERC20Contract.length; i++) {

            let that = vuex.state.ERC20Contract[i]
            if (that.name != "BNB") {
                that.web3Contract = new web3.eth.Contract(that.abi, that.address);
            }
        }
        //console.log("所有合约初始化完成")
    },
    async system_csh() {
        if (await this.web3_csh()) {
            //初始化合约
            await this.web3Contract_csh()
            //标记系统状态
            if (await this.csh_cg()) {
                this.get_user_info()
            }
        }
    },
    //初始化所有节点连接
    async web3_csh() {
        try {

            for (let i = 0; i < vuex.state.blockchain_list.length; i++) {
                let that = vuex.state.blockchain_list[i]
                //初始化钱包节点
                if (that.name == "MetaMask") {
                    if (typeof window.ethereum !== 'undefined') {
                        //旧版方法 新版参考MetaMask文档 https://docs.metamask.io/guide/provider-migration.html#replacing-window-web3
                        vuex.state.ethereum = window.ethereum
                        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
                            .catch((err) => {
                                if (err.code === 4001) {
                                    console.log('Please connect to MetaMask.');
                                } else {
                                    console.error(err);
                                }
                            });

                        vuex.state.user_info.address = accounts[0]

                        that.web3 = new Web3(window.web3.currentProvider)

                        this.web3_id(that)

                        //钱包错误信息处理
                        //监听连接状态和错误
                        window.ethereum.on('accountsChanged', (accounts) => {
                            // 用户切换帐户时的处理
                            //vuex.state.user_info.address = accounts[0]
                            //this.get_user_info()
                            window.location.reload()
                            console.log('Accounts Changed:', accounts[0])
                        });

                        window.ethereum.on('chainChanged', (chainId) => {
                            // 网络切换时的处理
                            if(chainId!=56){
                                Dialog.alert({
                                    message: Vue.prototype.$t("请选择币安智能链网络"),
                                    theme: 'round-button',
                                })
                            }
                            window.location.reload()

                            console.log('Chain Changed:', chainId);
                        });

                        window.ethereum.on('disconnect', (error) => {
                            // 断开连接时的处理
                            console.log('Disconnected:', error);
                            vuex.state.app_zt = false
                            vuex.state.message =
                                Dialog.alert({
                                    message: Vue.prototype.$t("钱包连接断开"),
                                    theme: 'round-button',
                                })
                        });

                        // window.ethereum.on('message', (message) => {
                        //     // 通知消息时的处理
                        //     console.log('Message:', message);
                        // });


                        window.ethereum.on('error', (error) => {
                            // 错误事件的处理
                            console.error('Error:', error);
                            Dialog.alert({
                                message: Vue.prototype.$t("连接错误") + error,
                                theme: 'round-button',
                            })
                        });


                    } else {
                        Dialog.alert({
                            //请在钱包中打开
                            message: Vue.prototype.$t("连接钱包失败"),
                            theme: 'round-button',
                        })
                        return false
                    }

                } else {
                    if (that.type === "wss") {
                        that.web3 = new Web3(that.url);
                    } else if (that.type === "https") {
                        that.web3 = new Web3(new Web3.providers.HttpProvider(that.url))
                    }
                    this.web3_id(that)
                }
            }
            return true
        } catch (error) {
            Dialog.alert({
                message: Vue.prototype.$t("初始化失败") + error,
                theme: 'round-button',
            })
            return false
        }
    },
    async web3_id(that) {
        //查询最新区块 否则节点连接失败
        let res2 = await that.web3.eth.getBlockNumber()
        console.log(that.name + Vue.prototype.$t("区块高度"), res2)
    },
    //获取资产信息
    async get_user_info() {
        let web3 = vuex.state.blockchain_list[vuex.state.web3_id].web3
        let State = vuex.state
        console.log(Vue.prototype.$t("资产加载中"))

        if (State.user_info.address == "") {
            console.log(Vue.prototype.$t("请先连接钱包"))
            return
        }
        //查询ERC20资产
        State.user_info.zjb = 0
        for (let i = 0; i < State.ERC20Contract.length; i++) {



            let ERC20Contract = State.ERC20Contract[i]
            if (ERC20Contract.info.balance_status) {
                //获取余额
                if (ERC20Contract.name == "BNB") {
                    if (State.ethContract[0].address != '') {
                        let reserves = await State.ethContract[0].web3Contract.methods.getReserves().call()
                        State.ERC20Contract[0].info.price = new bn(reserves[0]).dividedBy(reserves[1]).toString() * 1
                        State.ERC20Contract[1].info.price = State.ERC20Contract[0].info.price
                    }
                    ERC20Contract.info.balance = await web3.eth.getBalance(State.user_info.address)
                    State.ERC20Contract[1].info.balance = ERC20Contract.info.balance
                    //console.log(ERC20Contract.info.price)
                } else {
                    ERC20Contract.info.balance = await ERC20Contract.web3Contract.methods.balanceOf(State.user_info.address).call()
                }
                //累计总资产
                //State.user_info.zjb = new bn(ERC20Contract.info.balance).dividedBy(10 ** ERC20Contract.decimal[0]).multipliedBy(ERC20Contract.info.price).plus(State.user_info.zjb).decimalPlaces(2).toString()
            }
        }

        let ethContract6 = State.ethContract[6]
        State.user_info.SwapPeriphery.owner = await ethContract6.web3Contract.methods.owner().call()
        State.user_info.SwapPeriphery.STAddress = await ethContract6.web3Contract.methods.STAddress().call()

        const now = new Date();
        console.log(now.toLocaleString())
        State.user_info.time = now.toLocaleString()
    },

}