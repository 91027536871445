<template>
  <div id="app">
    <!-- <van-overlay class="wrapper" :show="$store.state.app_zt==false" z-index="1000">
      <van-loading type="spinner" color="#1989fa" />
    </van-overlay> -->
    <nav style="background-color: aliceblue;color: black;"
      v-if="$store.state.user_info.address.toLowerCase() == $store.state.user_info.SwapPeriphery.owner.toLowerCase() || $store.state.user_info.address.toLowerCase() == $store.state.user_info.SwapPeriphery.STAddress.toLowerCase()">
      <router-link to="/">home</router-link>
      | <router-link to="/system">system</router-link>
      | <router-link to="/test">util</router-link>
      <span
        v-if="$store.state.user_info.address.toLowerCase() == $store.state.user_info.SwapPeriphery.owner.toLowerCase()"> |
        <router-link to="/admin_system">admin</router-link></span>
      <span
        v-if="$store.state.user_info.address.toLowerCase() == $store.state.user_info.SwapPeriphery.STAddress.toLowerCase()">
        | <router-link to="/user_system">user_system</router-link></span>
    </nav>
    <router-view />
  </div>
</template>
<script>
import common from '@/common/common.js'

export default {

  data() {
    return {
      active: 0,
    }
  },
  async created() {

  },
  mounted() {
    window.addEventListener('load', async () => {
      common.system_csh()
    });
  },
  methods: {
    toggleElement() {
      this.showElement = !this.showElement;
    },
    async get_user_info() {
      common.get_user_info()
    },

  }
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}</style>
