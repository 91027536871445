import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/system',
    name: 'system',
    component: () => import('../views/SystemView.vue')
  },
  {
    path: '/admin_system',
    name: 'admin_system',
    component: () => import('../views/AdminSystemView.vue')
  },
  {
    path: '/user_system',
    name: 'user_system',
    component: () => import('../views/UserSystemView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/TestView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
